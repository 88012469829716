import { ApiRequest } from '@app/constants/ApiTypes/entities'
import { ApiDataResponse } from '@app/constants/ApiTypes/misc'
import { GetRequestsQuery } from '@app/constants/ApiTypes/requests'

import { withAbortSignal } from '@app/packages/abortContext/actions'

import { ApiActionPromise } from '@app/store/apiMiddleware/types'
import { createManager } from '@app/store/misc/MetaManager'
import { wrapApiPromise } from '@app/store/misc/wrapApiPromise'
import { createThunk } from '@app/store/thunk'

import { getRequests, getRequestsWithDebt } from './api/requests'

export const getFetchRequestsManager = () =>
  createManager<{
    loading: boolean
    loadedAt?: string
    next?: GetRequestsQuery | null
    getPromise?: () => ApiActionPromise<ApiDataResponse<ApiRequest[]> & { meta: { current_page: number; total_pages: number } }>
    abort?: () => void
  }>('requests/list', { loading: false })

export const fetchRequests = (query?: GetRequestsQuery) =>
  createThunk(async dispatch => {
    const manager = dispatch(getFetchRequestsManager())
    if (!query && manager.getMeta().loading) return manager.getMeta().getPromise?.()
    manager.getMeta().abort?.()
    const abortController = new AbortController()
    const q = query ?? manager.getMeta().next
    if (q === null) return
    if (!q) throw new Error('Query is missing')
    const promise = wrapApiPromise(dispatch(withAbortSignal(abortController.signal, getRequests(q))), () => abortController.abort(), manager)

    const resp = await promise
    if (resp && !resp.error) {
      manager.setMeta({
        ...manager.getMeta(),
        next: resp.payload.meta.current_page < resp.payload.meta.total_pages ? { ...q, page: resp.payload.meta.current_page + 1 } : null,
      })
    }
    return resp
  })

export const getFetchRequestsWithDebtManager = () =>
  createManager<{ loading: boolean; loadedAt?: string; getPromise?: () => ApiActionPromise<ApiDataResponse<ApiRequest[]>>; abort?: () => void }>(
    'requests/debts',
    { loading: false }
  )

export const fetchRequestsWithDebt = (force?: boolean) =>
  createThunk(async dispatch => {
    const manager = dispatch(getFetchRequestsWithDebtManager())
    if (manager.getMeta().loading) {
      if (force) {
        manager.getMeta().abort?.()
      } else {
        return await manager.getMeta().getPromise?.()
      }
    }
    const abortController = new AbortController()
    const promise = wrapApiPromise(dispatch(withAbortSignal(abortController.signal, getRequestsWithDebt())), () => abortController.abort(), manager)
    return await promise
  })
