import { AbortError, isAbortError } from '@app/errors/AbortError'

import { asError } from '@app/utils/asError'
import { intoResult, promiseIntoResult } from '@app/utils/intoResult'
import { TimeMeasurer } from '@app/utils/TimeMeasurer'
import { wrapError } from '@app/utils/wrapError'

import { getAbortSignal } from '@app/packages/abortContext/actions'

import { createThunk, ThunkAction } from '@app/store/thunk'

import { ApiError, FetchError, RequestError } from './errors'
import { ApiActionBundle, ApiActionDescriptor, ApiActionPromise } from './types'

type ApiActionDescriptorPayload<A> = A extends ApiActionDescriptor<ApiActionBundle<string, string, string, infer P, any>> ? P : never

export const apiActionToThunk = <A extends ApiActionDescriptor<ApiActionBundle<string, string, string, any, any>>>(action: A) => {
  return createThunk(async (dispatch, getState): ApiActionPromise<ApiActionDescriptorPayload<A>> => {
    dispatch({ type: action.types.at(0), meta: action.meta })

    try {
      const fetch = action.fetch ?? global.fetch

      const endpoint = typeof action.endpoint === 'function' ? action.endpoint(getState()) : action.endpoint
      const headers = typeof action.headers === 'function' ? action.headers(getState()) : action.headers

      const method = action.method ?? 'GET'

      const respResult = await promiseIntoResult(
        fetch(endpoint, {
          method,
          headers,
          credentials: action.credentials,
          signal: dispatch(getAbortSignal()),
          redirect: 'follow',
          body: action.body,
        })
      )
      if (respResult.error) {
        if (isAbortError(respResult.value)) throw respResult.value
        throw wrapError(new FetchError(respResult.value.message), respResult.value)
      }
      const resp = respResult.value
      await assertResponseHasNoError(resp, method, endpoint)

      const text = await resp.text()
      let data: ApiActionDescriptorPayload<A> = text ? JSON.parse(text) : undefined
      if (action.payload) {
        data = await action.payload(getState(), data)
      }

      const desc = {
        type: action.types[1],
        meta: action.meta,
        error: false,
        payload: data,
      }
      dispatch(desc)

      return desc
    } catch (e) {
      const desc = {
        type: action.types[2],
        meta: action.meta,
        error: true as const,
        payload: asError(e),
      }
      dispatch(desc)

      if (isAbortError(e)) {
        return undefined
      }

      return desc
    }
  })
}

export async function assertResponseHasNoError(resp: Response, method: string, endpoint: string) {
  if (resp.ok) return
  const text = await resp.text()
  const message = `${method} ${endpoint} call failed`

  if (!text) throw RequestError.create(resp.status, message, 'Missing response text')

  {
    const data = intoResult(() => JSON.parse(text))
    if (!data.error) throw new ApiError(resp.status, message, data.value)
  }

  {
    const parser = new DOMParser()
    const data = intoResult(() => parser.parseFromString(text, 'text/html'))
    if (!data.error && data.value) throw RequestError.create(resp.status, message, data.value.title || text)
  }

  throw RequestError.create(resp.status, message, text)
}

export const dispatchApiError = <
  A extends {
    type: string
    error: true
    meta?: object
    payload: Error
  },
>(
  action: A
) =>
  createThunk(
    (
      dispatch
    ): Promise<{
      error: true
      payload: A['payload']
    }> => {
      dispatch(action)
      return Promise.resolve({ error: true, payload: action.payload })
    }
  )

const loggedFetch = (orfetch: typeof fetch) => {
  const mfetch: typeof fetch = async (...args: Parameters<typeof fetch>) => {
    const time = new TimeMeasurer()
    let status = 0
    try {
      const resp = await orfetch(...args)
      status = resp.status
      return resp
    } catch (e) {
      // create error that has same name and message across different browsers
      if (e instanceof Error && e.name === 'AbortError') {
        throw new AbortError()
      }
      throw e
    } finally {
      if (process.env.LOG_FETCH) {
        const id = (args[1]?.headers as any)?.['X-Kidsout-App-Request-Id'] ?? ''
        const log = (status >= 400 ? console.error : console.info).bind(console)
        const url = (() => {
          try {
            if (typeof args[0] === 'string') return decodeURI(args[0]) || args[0]
            return args[0]
          } catch {
            return args[0]
          }
        })()
        log('[fetch]', id, args[1]?.method ?? 'GET', url, status, time.diff())
      }
    }
  }
  return mfetch
}

export function createApiAction<A extends ApiActionBundle<string, string, string, any, any>>({ ...payload }: ApiActionDescriptor<A>) {
  return apiActionToThunk({ credentials: 'omit', ...payload, fetch: loggedFetch(payload.fetch ?? fetch) })
}

const BOGUS_API_ACTION = '@API_ACTION/BOGUS_API_ACTION'

type BOGUS_ACTION<R> = ApiActionBundle<string, string, string, R, undefined>

/**
 * creates api action with omittes types.
 */
export function createTypelessApiAction<R = any>(payload: Omit<ApiActionDescriptor<BOGUS_ACTION<R>>, 'types'>): ThunkAction<ApiActionPromise<R>> {
  return createApiAction({
    ...payload,
    types: [BOGUS_API_ACTION, BOGUS_API_ACTION, BOGUS_API_ACTION],
  })
}

export function unwrapApiActionResultError<T>(p: ApiActionPromise<T>) {
  return p.then(resp => {
    if (resp?.error) throw resp.payload
    return resp?.payload
  })
}
