import { createSelector } from 'reselect'

import { IMPORT_MAP } from '@app/importMap'

import { asError } from '@app/utils/asError'
import { captureException } from '@app/utils/errorReport/errorReport'
import loggerCreator from '@app/utils/loggerCreator'
import moment, { Moment } from '@app/utils/moment'
import { waitBetween } from '@app/utils/waitBetween'
import { wrapError, wrapPromise } from '@app/utils/wrapError'

import { LazyPromise } from '@app/packages/LazyPromise/LazyPromise'

import { getCurrencies, restoreCurrency } from '@app/store/actions/currencies'
import { getContacts, getFeatures, getRegions, restoreTestFlights } from '@app/store/actions/initial'
import { setConfig } from '@app/store/actions/misc'
import { getPlans, restorePlanModifier } from '@app/store/actions/plan'
import { fetchBoundIdentities, getIdentities, restorePlace } from '@app/store/actions/profile'
import { getFetchRequestsWithDebtManager } from '@app/store/actions/requests'
import { restoreBanners, restoreRelatedApps } from '@app/store/actions/ui'
import { getCurrentUser } from '@app/store/actions/user'
import { unwrapApiActionResultError } from '@app/store/apiMiddleware/utils'
import { profileUserSelector } from '@app/store/selectors/profile'
import { Store, storeMonitoring } from '@app/store/store'

import { BuildChecker } from './BuildChecker'

type CheckerLabel = 'profile' | 'regions' | 'debts' | 'card' | 'news' | 'identities' | 'telegram_bot_connection'

export class DataRefreshService {
  private logger = loggerCreator('DataRefresh', 'lightcoral', undefined, true)
  private started = false
  private stopHandlers: (() => unknown)[] = []
  private store: Store

  private checkers = new Map<CheckerLabel, LazyPromise<any>>()

  private buildChecker: BuildChecker

  onPlaceRestore?: () => Promise<void>

  constructor(store: Store) {
    this.store = store
    this.buildChecker = new BuildChecker(this.store)
  }

  async initServer(): Promise<{ error: false } | { error: true; payload: Error }> {
    this.store.dispatch(restoreTestFlights())
    this.store.dispatch(restorePlanModifier())
    const planModifier = this.store.getState().plans.modifier

    const promises: Promise<any>[] = []

    this.store.dispatch(restoreBanners())

    const regionsPromise = unwrapApiActionResultError(this.store.dispatch(getRegions()))
    regionsPromise.catch(() => {})
    const plansPromise = unwrapApiActionResultError(this.store.dispatch(getPlans(planModifier)))
    plansPromise.catch(() => {})
    const currenciesPromise = unwrapApiActionResultError(this.store.dispatch(getCurrencies()))
    currenciesPromise.catch(() => {})

    const profilePromise = unwrapApiActionResultError(this.store.dispatch(getCurrentUser()))
      .then(waitBetween(Promise.all([regionsPromise, plansPromise, currenciesPromise])))
      .then(profile => {
        const accType = profile?.data.attributes.account_type
        if (!accType || accType === 'visitor') return null
        return accType
      })
      .then(accType => this.onProfileChange(accType))
    profilePromise.catch(() => {})

    const contactsPromise = profilePromise.then(() => unwrapApiActionResultError(this.store.dispatch(getContacts())))
    contactsPromise.catch(() => {})

    promises.push(regionsPromise, plansPromise, currenciesPromise, profilePromise, contactsPromise)

    try {
      await Promise.all(promises)
    } catch (e) {
      return { error: true, payload: asError(e) }
    }

    return { error: false }
  }

  async initBrowser() {
    this.logger('Init')

    if (this.isPolygon()) return

    await Promise.all([this.store.dispatch(restoreRelatedApps())])
    await this.check()
  }

  start() {
    if (this.started) return
    this.started = true
    this.logger('Start')

    const isApp = this.store.getState().config.isApp

    if (!isApp) {
      this.buildChecker.start(60 * 1000)
      const unsub = this.trackOnlineStatus()
      this.stopHandlers.push(unsub)
    }

    this.check()
    const interval = setInterval(() => {
      this.check().catch(e => {
        captureException(e, { tags: { service: 'DataRefreshService' } })
      })
    }, 60 * 1000)
    this.stopHandlers.push(() => {
      clearInterval(interval)
    })

    const unsubProfile = storeMonitoring(this.store, [profileDataSelector], async ([newData], [oldData]) => {
      if (newData && oldData?.id !== newData.id) this.onProfileChange(newData.account_type)
    })
    this.stopHandlers.push(unsubProfile)
  }

  stop() {
    if (!this.started) return
    this.logger('Stop')
    this.buildChecker.stop()
    this.stopHandlers.forEach(h => h())
    this.stopHandlers = []
    this.started = false
  }

  private upsertCheckers<T>(key: CheckerLabel, val: LazyPromise<T>) {
    if (!this.checkers.has(key)) {
      this.checkers.set(key, val)
    }
    return this.checkers.get(key) as LazyPromise<T>
  }

  private isPolygon() {
    return this.store.getState().config.isPolygon
  }

  private async check() {
    if (this.isPolygon()) return

    this.logger('Check')

    try {
      await Promise.all([
        this.checkRegions(),
        this.checkProfile(),
        this.checkDebts(),
        this.checkCard(),
        this.checkNews(),
        this.checkIdentities(),
        this.checkTelegramBotConnections(),
      ])
    } catch (e) {
      throw wrapError(new Error('Profile check failed'), e)
    } finally {
      this.logger('Check ended')
    }
  }

  private async checkRegions() {
    await this.processChecker(
      'regions',
      new LazyPromise(async () => {
        const loadedAt = this.store.getState().regions.state.loadedAt
        if (loadedAt && !this.isExpired(moment(loadedAt), this.getExpirationDate(60))) return
        this.logger('Check regions')
        await unwrapApiActionResultError(this.store.dispatch(getRegions()))
      }),
      this.getExpirationDate(60)
    )
  }

  private async checkProfile() {
    await this.processChecker(
      'profile',
      new LazyPromise(async () => {
        const state = this.store.getState()
        if (!state.profile.user) return
        const loadedAt = state.profile.meta.loadedAt
        if (loadedAt && !this.isExpired(moment(loadedAt), this.getExpirationDate(1))) return
        this.logger('Check profile')
        await wrapPromise(unwrapApiActionResultError(this.store.dispatch(getCurrentUser())), new Error('Failed to get current user'))
      }),
      this.getExpirationDate(1)
    )
  }

  private async checkDebts() {
    await this.processChecker(
      'debts',
      new LazyPromise(async () => {
        await this.checkProfile()
        const state = this.store.getState()
        if (state.profile.user?.account_type !== 'parent') return
        const debtsManager = this.store.dispatch(getFetchRequestsWithDebtManager())
        const loadedAt = debtsManager.getMeta().loadedAt
        if (loadedAt && !this.isExpired(moment(loadedAt), this.getExpirationDate(5))) return
        this.logger('Check debts')
        const { fetchRequestsWithDebt } = await import('@app/store/actions/requests')
        await this.store.dispatch(fetchRequestsWithDebt())
      }),
      this.getExpirationDate(5)
    )
  }

  private async checkCard() {
    await this.processChecker(
      'card',
      new LazyPromise(async () => {
        await this.checkProfile()
        const state = this.store.getState()
        if (state.profile.user?.account_type !== 'parent') return
        const loadedAt = state.card.meta.loadedAt
        if (loadedAt && !this.isExpired(moment(loadedAt), this.getExpirationDate(5))) return
        this.logger('Check card')
        const { getCard } = await IMPORT_MAP.actions.payment()
        await wrapPromise(unwrapApiActionResultError(this.store.dispatch(getCard(true))), new Error('Failed to get card'))
      }),
      this.getExpirationDate(5)
    )
  }

  private async checkNews() {
    this.processChecker(
      'news',
      new LazyPromise(async () => {
        await this.checkProfile()
        const state = this.store.getState()
        if (!state.profile.user || state.profile.user.account_type === 'visitor') return
        const fetchedAt = state.news.fetchedAt
        if (fetchedAt && !this.isExpired(moment(fetchedAt), this.getExpirationDate(120))) return
        this.logger('Check news')
        const { fetchInitialNews } = await IMPORT_MAP.actions.news()
        await wrapPromise(unwrapApiActionResultError(this.store.dispatch(fetchInitialNews(true))), new Error('Failed to get initial news'))
      }),
      this.getExpirationDate(120)
    )
  }

  private async checkIdentities() {
    await this.processChecker(
      'identities',
      new LazyPromise(async () => {
        await this.checkProfile()
        const state = this.store.getState()
        if (!state.profile.user || state.profile.user.account_type === 'visitor') return
        const loadedAt = state.profile.socialIdentities.fetched_at
        if (loadedAt && !this.isExpired(moment(loadedAt), this.getExpirationDate(5))) return
        this.logger('Check identites')
        await wrapPromise(unwrapApiActionResultError(this.store.dispatch(getIdentities(true))), new Error('Failed to check identities'))
      }),
      this.getExpirationDate(5)
    )
  }

  private async checkTelegramBotConnections() {
    await this.processChecker(
      'telegram_bot_connection',
      new LazyPromise(async () => {
        await this.checkProfile()
        const state = this.store.getState()
        if (!state.profile.user || state.profile.user.account_type === 'visitor') return

        const loadedAt = state.telegram_bot_connections.meta.loaded_at
        if (loadedAt && !this.isExpired(moment(loadedAt), this.getExpirationDate(5))) return

        this.logger('Check telegram_bot_connection')
        const { getTelegramBotConnections } = await IMPORT_MAP.actions.telegram()
        await wrapPromise(unwrapApiActionResultError(this.store.dispatch(getTelegramBotConnections())), new Error('Failed to get telegram bot connections'))
      }),
      this.getExpirationDate(5)
    )
  }

  private getExpirationDate(minutes: number) {
    return moment().subtract(minutes, 'minutes')
  }

  private async processChecker<T>(key: CheckerLabel, p: LazyPromise<T>, expireDate: Moment): Promise<T> {
    const checker = this.upsertCheckers(key, p)
    if (checker.loading.value) return checker.getValue()
    if (checker.loadedAt.value && !this.isExpired(checker.loadedAt.value, expireDate)) return checker.getValue()
    checker.unset()
    return await checker.getValue()
  }

  private isExpired(ts: Moment | null, base: Moment) {
    if (!ts) return true
    return ts.isBefore(base)
  }

  private async onProfileChange(accountType: 'parent' | 'sitter' | null) {
    await this.store.dispatch(restorePlace())

    const promises: Promise<any>[] = []

    promises.push(unwrapApiActionResultError(this.store.dispatch(restoreCurrency())), unwrapApiActionResultError(this.store.dispatch(getFeatures())))

    if (accountType) {
      promises.push(
        IMPORT_MAP.actions.trusted().then(m => unwrapApiActionResultError(this.store.dispatch(m.fetchTrusted()))),
        IMPORT_MAP.actions.news().then(m => unwrapApiActionResultError(this.store.dispatch(m.fetchInitialNews())))
      )

      if (accountType === 'parent') {
        const { getCard } = await IMPORT_MAP.actions.payment()
        const { fetchRequestsWithDebt } = await import('@app/store/actions/requests')
        promises.push(
          unwrapApiActionResultError(this.store.dispatch(getCard(true))),
          unwrapApiActionResultError(this.store.dispatch(fetchBoundIdentities())),
          unwrapApiActionResultError(this.store.dispatch(fetchRequestsWithDebt()))
        )
      }
    }

    await Promise.all(promises).then(() => wrapPromise(this.onPlaceRestore?.() ?? Promise.resolve(), new Error('Failed to restore place')))
  }

  /** tracks online/offline status so we can show bar that warns when connection lost */
  private trackOnlineStatus() {
    const handler = () => {
      this.store.dispatch(setConfig({ online: window.navigator.onLine }))
    }
    window.addEventListener('online', handler)
    window.addEventListener('offline', handler)

    return () => {
      window.removeEventListener('online', handler)
      window.removeEventListener('offline', handler)
    }
  }
}

const profileDataSelector = createSelector([profileUserSelector], profile => {
  if (!profile) return null
  if (profile.account_type === 'visitor') return null
  return { id: profile.id, account_type: profile.account_type }
})
