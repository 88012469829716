import * as api from '@app/utils/api'

import { ApiActionBuilder } from '@app/store/apiMiddleware/builder'
import { ApiError, asNetworkError } from '@app/store/apiMiddleware/errors'
import { ApiActionPromise } from '@app/store/apiMiddleware/types'
import { createThunk } from '@app/store/thunk'
import { StoreUser } from '@app/store/types/users'

import { getUsersById, getUsersByToken, getUsersMe } from './api/users'
import { setSession } from './session'
import { getTrainingRequest } from './trainingRequest'
import { resendEmailConfirmationDescriptor, sendEmailConfirmationDescriptor } from './user.descriptors'

const GET_CURRENT_USER_SYMBOL = Symbol('GET_CURRENT_USER_SYMBOL')

export function getCurrentUser({
  force = false,
}: {
  force?: boolean
} = {}) {
  return createThunk(async (dispatch, getState, { promiseManager }) => {
    if (force) promiseManager.discard(GET_CURRENT_USER_SYMBOL)
    return promiseManager.create(
      GET_CURRENT_USER_SYMBOL,
      async () => {
        const shouldLoadProfile = (() => {
          if (!getState().session.access_token) return false
          if (getState().profile.user && !force) return false
          return true
        })()
        const profilePromise = (shouldLoadProfile ? dispatch(getUsersMe()) : Promise.resolve(undefined)).then(resp => {
          if (resp?.error && asNetworkError(resp.payload)?.status === 401) return undefined
          return resp
        })

        const sessionUpdatePromise = profilePromise.then(async response => {
          if (!response || response.error) return
          const state = getState()

          await dispatch(
            setSession({
              access_token: state.session.access_token || '',
            })
          )
        })

        const trainingPromise = profilePromise.then(async () => {
          const user = getState().profile.user
          if (user && user.account_type === 'sitter' && !user.approved && !user.training_completed && user.has_training_request) {
            await dispatch(getTrainingRequest())
          }
        })

        const promise = Promise.all([profilePromise, sessionUpdatePromise, trainingPromise]).then(([profileResp]) => profileResp)

        const result = await promise
        return result
      },
      {
        freshness: force ? -1 : 0,
        discard: res => res.error,
      }
    )
  })
}

const fetchUserPromisesKey = Symbol('fetchUserPromises')

export function getFetchUserPromises() {
  return createThunk((_dispatch, _getState, context) => {
    if (!context[fetchUserPromisesKey]) {
      context[fetchUserPromisesKey] = new Map<string, ApiActionPromise<StoreUser>>()
    }
    return context[fetchUserPromisesKey] as Map<string, ApiActionPromise<StoreUser>>
  })
}

export function fetchUser(
  payload: { type: 'id'; value: string } | { type: 'token'; value: string },
  { force = false, ensureAccountType }: { force?: boolean; ensureAccountType?: 'sitter' | 'parent' } = {}
) {
  return createThunk<ApiActionPromise<StoreUser>>(async (dispatch, getState) => {
    const fetchUserPromises = dispatch(getFetchUserPromises())

    if (payload.value.includes('support'))
      return {
        error: true,
        payload: new ApiError(404, `Attempt to fetch user with id: ${payload.value}`, undefined),
      }

    const state = getState()
    const user = state.users.models[payload.value]

    if (!force && user && user.avatarsComplete) {
      if (ensureAccountType && user.account_type !== ensureAccountType) return { error: true, payload: new Error('Invalid account type') }
      return { error: false, payload: user }
    }

    const promiseId = `${payload.type}:${payload.value}`

    const promise = (() => {
      const cached = fetchUserPromises.get(promiseId)
      if (cached) return cached

      const promise = dispatch(payload.type === 'id' ? getUsersById(payload.value) : getUsersByToken(payload.value)).then(p => {
        fetchUserPromises.delete(promiseId)
        if (p?.error) return p

        const user = getState().users.models[payload.value]
        if (!user) return { error: true as const, payload: new Error('User not found') }
        if (ensureAccountType && user.account_type !== ensureAccountType) {
          return { error: true as const, payload: new Error('Invalid account type') }
        }

        return { error: false as const, payload: user }
      })

      fetchUserPromises.set(promiseId, promise)

      return promise
    })()

    return promise
  })
}

export const sendEmailConfirmation = new ApiActionBuilder(sendEmailConfirmationDescriptor)
  .setInit((token: string) => ({
    method: 'POST',
    endpoint: api.path('/api/v2/email_confirmation'),
    headers: api.headers(),
    body: JSON.stringify({ token }),
    bailout: ({ emailConfirmation }) => !!(emailConfirmation.data.email || emailConfirmation.data.error),
  }))
  .build()

export const resendEmailConfirmation = new ApiActionBuilder(resendEmailConfirmationDescriptor)
  .setInit(() => ({
    method: 'POST',
    endpoint: api.path('/api/v2/email_confirmation/resend'),
    headers: api.headers(),
  }))
  .build()
