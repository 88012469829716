import { ApiDirectAnnouncement, ApiDirectAnnouncementPrice } from '@app/constants/ApiTypes/entities'
import { ApiDataResponse } from '@app/constants/ApiTypes/misc'
import { DirectAnnouncementRequest, PutDirectAnnouncementsAcceptRequest } from '@app/constants/ApiTypes/requests'

import { ApiActionBuilderDescriptor } from '@app/store/apiMiddleware/builderDescriptor'

export const postDirectAnnouncementsDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('postDirectAnnouncements')
  .setShape<ApiDataResponse<ApiDirectAnnouncement>, { request: DirectAnnouncementRequest }>()

export const putDirectAnnouncementsAcceptDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('putDirectAnnouncementsAccept')
  .setShape<ApiDataResponse<ApiDirectAnnouncement>, { direct_announcement_id: string; request: PutDirectAnnouncementsAcceptRequest }>()

export const putDirectAnnouncementsCancelDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('putDirectAnnouncementsCancel')
  .setShape<ApiDataResponse<ApiDirectAnnouncement>, { direct_announcement_id: string }>()

export const putDirectAnnouncementsDeclineDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('putDirectAnnouncementsDecline')
  .setShape<ApiDataResponse<ApiDirectAnnouncement>, { direct_announcement_id: string }>()

export const getDirectAnnouncementsByIdDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('getDirectAnnouncementsById')
  .setShape<ApiDataResponse<ApiDirectAnnouncement>, { direct_announcement_id: string }>()

export const postDirectAnnouncementsCalculateDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('postDirectAnnouncementsCalculate')
  .setShape<ApiDataResponse<ApiDirectAnnouncementPrice>>()
