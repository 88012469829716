import type {
  ApiAvatar,
  ApiCountry,
  ApiLocality,
  ApiParent,
  ApiParentProfile,
  ApiPhoneCodeDelivery,
  ApiPhoneVerification,
  ApiPlace,
  ApiRegion,
  ApiSitter,
  ApiSitterProfile,
  ApiSocialIdentity,
  ApiUserProfile,
} from '@app/constants/ApiTypes/entities'
import type { ApiDataResponse, PhoneVerificationMethod } from '@app/constants/ApiTypes/misc'
import type { ApiDeleteFavoritesUserQuery, ApiPostFavoritesUserRequest, PhoneVerificationRequest } from '@app/constants/ApiTypes/requests'

import { ApiActionBuilderDescriptor } from '@app/store/apiMiddleware/builderDescriptor'
import { createAction } from '@app/store/toolkit'
import type { ProfileUpdateState } from '@app/store/types/profile'

export const editProfile = createAction<'EDIT_PROFILE', ProfileUpdateState>('EDIT_PROFILE')

export const getPhoneDeliveryMethodsDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('GET_PHONE_DELIVERY_METHODS')
  .setShape<ApiDataResponse<ApiPhoneCodeDelivery[]>>()

export const postAuthPhoneRequestCodeDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('POST_AUTH_PHONE_REQUEST_CODE')
  .setShape<ApiDataResponse<ApiPhoneVerification>, { phone: string; purpose: 'auth' | 'phone_change'; delivery: PhoneVerificationMethod }>()

export const postAuthPhoneVerifyDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('POST_AUTH_PHONE_VERIFY')
  .setShape<ApiDataResponse<ApiUserProfile & { meta: { access_token: string } }>, { data: PhoneVerificationRequest }>()

export const phoneChangeConfirmDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('PHONE_CHANGE_CONFIRM')
  .setShape<ApiDataResponse<ApiUserProfile>, { data: { phone: string; code: string } }>()

export const emailConfirmDescriptor = new ApiActionBuilderDescriptor().setLabel('LOGIN_EMAIL_CONFIRMATION').setShape<void, { email: string; cause?: string }>()

export const postEmailTokenVerificationDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('POST_EMAIL_TOKEN_VERIFICATION')
  .setShape<
    ApiDataResponse<ApiUserProfile & { meta: { access_token: string } }> & { meta: { email_token_payload: { email: string; cause: string } } },
    { token: string }
  >()

export const postEmailCodeVerificationDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('POST_EMAIL_CODE_VERIFICATION')
  .setShape<ApiDataResponse<ApiUserProfile & { meta: { access_token: string } }>, { email: string; code: string }>()

export const appendAvatarIds = createAction<'APPEND_AVATAR_IDS', string[]>('APPEND_AVATAR_IDS')
export const addAvatar = createAction<'ADD_AVATAR', { avatarId: string; avatar: { id: string } & ApiAvatar['attributes'] }>('ADD_AVATAR')

export const deleteAvatarDescriptor = new ApiActionBuilderDescriptor().setLabel('DELETE_AVATAR').setShape<unknown, { avatarId: string }>()

export const setUploadAvatar = createAction<'SET_UPLOAD_AVATAR', { id: string; preview: string; percent: number; rejected?: boolean }>('SET_UPLOAD_AVATAR')

export const deleteUploadAvatar = createAction<'DELETE_UPLOAD_AVATAR', { id: string }>('DELETE_UPLOAD_AVATAR')

export const postTOSAcceptanceDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('POST_TOS_ACCEPTANCE')
  .setShape<ApiDataResponse<ApiParentProfile | ApiSitterProfile>>()

export const getFavoritesDescriptor = new ApiActionBuilderDescriptor().setLabel('GET_FAVORITES').setShape<
  ApiDataResponse<(ApiSitter | ApiParent)[]> & {
    meta: {
      total: number
      total_pages: number
      current_page: number
    }
  },
  {
    query: {
      per_page: number
      page: number
    }
  }
>()

export const addToFavoritesDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('ADD_TO_FAVORITES')
  .setShape<ApiDataResponse<ApiSitter | ApiParent>, ApiPostFavoritesUserRequest>()

export const deleteFromFavoritesDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('DELETE_FROM_FAVORITES')
  .setShape<ApiDataResponse<ApiSitter | ApiParent>, ApiDeleteFavoritesUserQuery>()

export const getIdentitiesDescriptor = new ApiActionBuilderDescriptor().setLabel('GET_IDENTITIES').setShape<{ data: ApiSocialIdentity[] }>()

export const postIdentitiesDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('POST_IDENTITIES')
  .setShape<{ data: ApiSocialIdentity }, { provider: ApiSocialIdentity['attributes']['provider']; token: string }>()

export const deleteIdentitiesByIdDescriptor = new ApiActionBuilderDescriptor().setLabel('DELETE_IDENTITIES_BY_ID').setShape<unknown, { id: string }>()

export const setProfilePlaceActionDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('SET_PROFILE_PLACE')
  .setShape<{ data: ApiPlace; included: (ApiRegion | ApiLocality | ApiCountry)[] }, { cause: 'change' | 'restore' }>()
