import some from 'lodash/some'

import { excludeAbortError } from '@app/errors/AbortError'

import { putUsersByIdDescriptor } from '@app/store/actions/api/users.descriptors'
import { postFirebaseTokensDescriptor } from '@app/store/actions/faye.descriptors'
import {
  resetSessionErrors,
  sessionDestroyActionDescriptor,
  setSessionAction,
  setShortSession,
  socialLoginActionDescriptor,
} from '@app/store/actions/session.descriptors'
import { postAuthTelegramDescriptor } from '@app/store/actions/telegram.descriptors'
import { isApiError } from '@app/store/apiMiddleware/errors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  /** short session uid */
  uuid: string
  access_token: string | null
  persistent?: boolean
  /**
   * indicates that login was completed via magic url,
   * e.g .../?access_token=...
   */
  viaURL?: boolean
  registration_error: Error | null
  error: Error | null
  not_registered: boolean | null
  firebase_token: string | null
  supervise: boolean
  supervisor: {
    /**
     * indicates that login was completed via magic url,
     * e.g .../?access_token=...
     */
    access_token: string
    viaURL?: boolean
  } | null
}>(
  { uuid: '', access_token: null, registration_error: null, error: null, not_registered: null, firebase_token: null, supervise: false, supervisor: null },
  builder => {
    builder.addCase(setSessionAction.shape, (state, action) => {
      return { ...state, ...action.payload, error: null, registration_error: null, not_registered: null }
    })

    builder.addCase(sessionDestroyActionDescriptor.shapes.fulfilled, (state, _action) => {
      return { ...state, access_token: null, error: null, registration_error: null, not_registered: null }
    })

    builder.addCase(resetSessionErrors.shape, (state, _action) => {
      return { ...state, registration_error: null }
    })

    builder.addCases([socialLoginActionDescriptor.shapes.rejected, postAuthTelegramDescriptor.shapes.rejected], (state, action) => {
      const errors = isApiError(action.payload) ? action.payload.response?.errors : []
      return { ...state, not_registered: some(errors, { code: 'NOT_REGISTERED' }), registration_error: excludeAbortError(action.payload) }
    })

    builder.addCase(putUsersByIdDescriptor.shapes.rejected, (state, action) => {
      const error = excludeAbortError(action.payload)
      return { ...state, error }
    })

    builder.addCase(postFirebaseTokensDescriptor.shapes.fulfilled, (state, action) => {
      if (action.payload.data?.id) return { ...state, firebase_token: action.payload.data.id }
      return state
    })

    builder.addCase(putUsersByIdDescriptor.shapes.fulfilled, (state, _action) => {
      return { ...state, error: null }
    })

    builder.addCase(setShortSession.shape, (state, action) => {
      return { ...state, uuid: action.payload }
    })
  }
)
