import * as valita from '@badrap/valita'

import { ApiEntity } from '@app/constants/ApiTypes/entities'

import { ApiTypesMap, normalize } from './normalizer'

export const apiNormalize = (input: unknown): ApiTypesMap<ApiEntity> => {
  try {
    const action = RT_ACTION.parse(input, { mode: 'passthrough' })
    return normalize(action.payload)
  } catch {
    return {}
  }
}

const RT_ACTION = valita.object({
  type: valita.string().assert(v => v.endsWith('_FULFILLED')),
  payload: valita.unknown().map(v => v as any),
})
