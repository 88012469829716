import { ApiRequest } from '@app/constants/ApiTypes/entities'

import { modifyV2EntityAttributes } from '@app/utils/modifyV2EntityAttributes'
import { normalize } from '@app/utils/normalizer'

import {
  getRequestsByIdDescriptor,
  getRequestsDescriptor,
  getRequestsWithDebtDescriptor,
  postRequestsDescriptor,
  putRequestsAcceptDescriptor,
  putRequestsCancelDescriptor,
  putRequestsDeclineDescriptor,
  putRequestsRejectDescriptor,
} from '@app/store/actions/api/requests.descriptors'
import { postRequestReviewsDescriptor } from '@app/store/actions/reviews.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  [key: string]: ApiRequest
}>({}, builder => {
  builder.addCases(
    [
      getRequestsWithDebtDescriptor.shapes.fulfilled,
      getRequestsDescriptor.shapes.fulfilled,
      getRequestsByIdDescriptor.shapes.fulfilled,
      postRequestsDescriptor.shapes.fulfilled,
      putRequestsAcceptDescriptor.shapes.fulfilled,
      putRequestsDeclineDescriptor.shapes.fulfilled,
      putRequestsCancelDescriptor.shapes.fulfilled,
      putRequestsRejectDescriptor.shapes.fulfilled,
    ],
    (state, action) => {
      const { requests } = normalize(action.payload)
      if (!requests) return state
      return { ...state, ...requests }
    }
  )

  builder.addCase(postRequestReviewsDescriptor.shapes.fulfilled, (state, action) => {
    const { request_id, reviewer } = action.meta
    const request = state[request_id]
    if (!request) return state

    const attrs: Partial<ApiRequest['attributes']> = reviewer === 'parent' ? { has_parent_review: true } : { has_sitter_review: true }
    return { ...state, [request_id]: modifyV2EntityAttributes(request, attrs) }
  })
})
