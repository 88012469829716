import { excludeAbortError } from '@app/errors/AbortError'

import { createDirectAnnouncementResponseDescriptor } from '@app/store/actions/announcementResponse.descriptors'
import { getDirectAnnouncementsByIdDescriptor } from '@app/store/actions/api/direct_announcements.descriptors'
import { createReducer } from '@app/store/toolkit'

interface Meta {
  loading: boolean
  updating: boolean
  error: Error | null
}

const defaultMeta: Meta = {
  loading: false,
  updating: false,
  error: null,
}

export default createReducer<{
  __default: Meta
  [key: string]: Meta
}>({ __default: defaultMeta }, builder => {
  builder.addCases([getDirectAnnouncementsByIdDescriptor.shapes.pending, createDirectAnnouncementResponseDescriptor.shapes.pending], (state, action) => {
    const id = action.meta.direct_announcement_id
    return { ...state, [id]: { ...state[id], loading: true, error: null } }
  })

  builder.addCases([getDirectAnnouncementsByIdDescriptor.shapes.fulfilled, createDirectAnnouncementResponseDescriptor.shapes.fulfilled], (state, action) => {
    const id = action.payload.data.id
    return { ...state, [id]: { ...state[id], loading: false, error: null } }
  })

  builder.addCases([getDirectAnnouncementsByIdDescriptor.shapes.rejected, createDirectAnnouncementResponseDescriptor.shapes.rejected], (state, action) => {
    const id = action.meta.direct_announcement_id
    return { ...state, [id]: { ...state[id], loading: false, error: excludeAbortError(action.payload) } }
  })
})
