import React, { CSSProperties, FunctionComponent } from 'react'

import { extractErrors } from '@app/utils/extractErrors'

import { useClickHandler } from '@app/hooks/useClickHandler'

import { InlineIcon } from '@app/components/Icon/Icon'

import classes from './ErrorRenderer.module.scss'

type ErrorKind = Error | null | undefined

export type ErrorRendererError = ErrorKind | ErrorKind[]

interface Props {
  className?: string
  style?: CSSProperties
  error?: ErrorRendererError
  retry?: () => unknown
}

export const ErrorRenderer: FunctionComponent<Props> = ({ className, style, error, retry }) => {
  const extracted = extractErrors(...(Array.isArray(error) ? error : [error]))
  const retryHandler = useClickHandler(() => retry?.())
  const retryBlock = retry ? (
    <span {...retryHandler} className={classes.retry}>
      <InlineIcon icon="refresh" />
    </span>
  ) : undefined

  if (!error || !extracted.length) return null

  return (
    <div className={cn(classes.root, className)} style={style}>
      {extracted.map((e, i) => (
        <p key={e}>
          {e}
          {i === extracted.length - 1 && ' '}
          {i === extracted.length - 1 && retryBlock}
        </p>
      ))}
    </div>
  )
}
