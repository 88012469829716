import type { SearchParams } from '@app/constants/StoreTypes'

import { Point } from '@app/packages/geo/Point'

import { createAction } from '@app/store/toolkit'

export const setSearchParams = createAction<'setSearchParams', SearchParams>('setSearchParams')
export const setMapResultMeta = createAction<'setMapResultMeta', { count: number }>('setMapResultMeta')

export const setMapSearchResult = createAction<
  'setMapSearchResult',
  {
    list: { id: string; token: string; name: string; coords: Point }[]
    initial: boolean
    bounds: ymaps.Bounds
  }
>('setMapSearchResult')
