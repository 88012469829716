import { ApiParent, ApiSitter } from '@app/constants/ApiTypes/entities'

import { ApiActionBuilderDescriptor } from '@app/store/apiMiddleware/builderDescriptor'

export const fetchTrustedSittersDescriptor = new ApiActionBuilderDescriptor().setLabel('FETCH_TRUSTED_SITTERS').setShape<{ data: ApiSitter[] }>()
export const fetchTrustingParentsDescriptor = new ApiActionBuilderDescriptor().setLabel('FETCH_TRUSTING_PARENTS').setShape<{ data: ApiParent[] }>()
export const addToTrustedDescriptor = new ApiActionBuilderDescriptor().setLabel('ADD_SITTER_TO_TRUSTED').setShape<undefined, { sitter_id: ApiSitter['id'] }>()
export const removeFromTrustedDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('REMOVE_SITTER_FROM_TRUSTING')
  .setShape<undefined, { sitter_id: ApiSitter['id'] }>()
