import { ProfileUpdateRequest } from '@app/constants/ApiTypes/requests'

import * as api from '@app/utils/api'
import { urlEscaped } from '@app/utils/urlEscaped'

import { ApiActionBuilder } from '@app/store/apiMiddleware/builder'

import {
  deleteUsersByIdDescritor,
  getUsersByFilterDescriptor,
  getUsersByIdDescriptor,
  getUsersByIdPerksDescriptor,
  getUsersByTokenDescriptor,
  getUsersMeDescriptor,
  putUsersByIdDescriptor,
  putUsersByIdPerksDescriptor,
  putUsersByIdPhoneDescriptor,
} from './users.descriptors'

export const getUsersById = new ApiActionBuilder(getUsersByIdDescriptor)
  .setInit((id: string) => ({
    method: 'GET',
    endpoint: api.path(urlEscaped`/api/v2/users/${id}`),
    headers: api.headers(),
    meta: { id },
  }))
  .build()

export const getUsersByToken = new ApiActionBuilder(getUsersByTokenDescriptor)
  .setInit((token: string) => ({
    method: 'GET',
    endpoint: api.path(urlEscaped`/api/v2/users/by_token/${token}`),
    headers: api.headers(),
    meta: { token },
  }))
  .build()

export const getUsersByFilter = new ApiActionBuilder(getUsersByFilterDescriptor)
  .setInit((filter: { ids: string[]; account_type: 'parent' | 'sitter' }) => ({
    method: 'GET',
    endpoint: api.path('/api/v2/users', {
      'filter[id]': filter.ids,
      'filter[account_type]': filter.account_type,
    }),
    headers: api.headers(),
  }))
  .build()

export const getUsersMe = new ApiActionBuilder(getUsersMeDescriptor)
  .setInit((token?: string) => ({
    method: 'GET',
    endpoint: api.path('/api/v2/users/me'),
    headers: api.headers(token ? { Token: token } : {}),
  }))
  .build()

export const putUsersById = new ApiActionBuilder(putUsersByIdDescriptor)
  .setInit((id: string, request: ProfileUpdateRequest) => ({
    method: 'PUT',
    endpoint: api.path(urlEscaped`/api/v2/users/${id}`),
    headers: api.headers(),
    body: JSON.stringify(request),
  }))
  .build()

export const deleteUsersById = new ApiActionBuilder(deleteUsersByIdDescritor)
  .setInit((id: string) => ({
    method: 'DELETE',
    endpoint: api.path(urlEscaped`/api/v2/users/${id}`),
    headers: api.headers(),
  }))
  .build()

export const getUsersByIdPerks = new ApiActionBuilder(getUsersByIdPerksDescriptor)
  .setInit((user_id: string) => ({
    method: 'GET',
    endpoint: api.path(urlEscaped`/api/v2/users/${user_id}/perks`),
    headers: api.headers(),
    meta: { user_id },
  }))
  .build()

export const putUsersByIdPerks = new ApiActionBuilder(putUsersByIdPerksDescriptor)
  .setInit((user_id: string, perk_ids: string[]) => ({
    method: 'PUT',
    endpoint: api.path(urlEscaped`/api/v2/users/${user_id}/perks`),
    headers: api.headers(),
    body: JSON.stringify({ perk_ids }),
    meta: { user_id, perk_ids },
  }))
  .build()

export const putUsersByIdPhone = new ApiActionBuilder(putUsersByIdPhoneDescriptor)
  .setInit((user_id: string, request: { phone: string; code: string }) => ({
    method: 'PUT',
    endpoint: api.path(urlEscaped`/api/v2/users/${user_id}/phone`),
    headers: api.headers(),
    body: JSON.stringify(request),
    meta: { user_id, request },
  }))
  .build()
