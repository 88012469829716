import type { ApiParentProfile, ApiSitterProfile, ApiTelegramBotConnection, ApiTelegramCode } from '@app/constants/ApiTypes/entities'
import { ApiDataResponse } from '@app/constants/ApiTypes/misc'

import { ApiActionBuilderDescriptor } from '@app/store/apiMiddleware/builderDescriptor'

export const getTelegramCodeDescriptor = new ApiActionBuilderDescriptor().setLabel('GET_TELEGRAM_CODE').setShape<{ data: ApiTelegramCode }>()
export const getTelegramBotConnectionsDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('GET_TELEGRAM_BOT_CONNECTIONS')
  .setShape<{ data: ApiTelegramBotConnection[] }>()
export const postTelegramBotConnectionsDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('POST_TELEGRAM_BOT_CONNECTIONS')
  .setShape<{ data: ApiTelegramBotConnection }>()
export const deleteTelegramBotConnectionsDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('DELETE_TELEGRAM_BOT_CONNECTIONS')
  .setShape<undefined, { id: string }>()
export const postAuthTelegramDescriptor = new ApiActionBuilderDescriptor().setLabel('POST_AUTH_TELEGRAM').setShape<
  ApiDataResponse<
    (ApiParentProfile | ApiSitterProfile) & {
      meta: { access_token: string }
    }
  >
>()
