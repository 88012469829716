import { compact } from '@app/utils/compactV2Entity'
import { ensureApiType } from '@app/utils/ensureApiType'
import { mergeGuarded } from '@app/utils/mergeGuarded'

import { getUsersMeDescriptor, putUsersByIdDescriptor } from '@app/store/actions/api/users.descriptors'
import {
  addAvatar,
  deleteAvatarDescriptor,
  phoneChangeConfirmDescriptor,
  postAuthPhoneVerifyDescriptor,
  postEmailCodeVerificationDescriptor,
  postEmailTokenVerificationDescriptor,
  postTOSAcceptanceDescriptor,
} from '@app/store/actions/profile.descriptors'
import { socialLoginActionDescriptor } from '@app/store/actions/session.descriptors'
import { postAuthTelegramDescriptor } from '@app/store/actions/telegram.descriptors'
import { postTrainingRequestDescriptor } from '@app/store/actions/trainingRequest.descriptors'
import { createReducer } from '@app/store/toolkit'
import { StoreParentProfile, StoreProfileUser, StoreSitterProfile, StoreVisitorProfile } from '@app/store/types/profile'

export default createReducer<StoreProfileUser>(null, builder => {
  builder.addCases(
    [
      getUsersMeDescriptor.shapes.fulfilled,
      socialLoginActionDescriptor.shapes.fulfilled,
      postAuthTelegramDescriptor.shapes.fulfilled,
      postTOSAcceptanceDescriptor.shapes.fulfilled,
      postAuthPhoneVerifyDescriptor.shapes.fulfilled,
      phoneChangeConfirmDescriptor.shapes.fulfilled,
      putUsersByIdDescriptor.shapes.fulfilled,
      postEmailCodeVerificationDescriptor.shapes.fulfilled,
      postEmailTokenVerificationDescriptor.shapes.fulfilled,
    ],
    (state, action) => {
      const user = action.payload.data
      if (!user) throw new Error(`Got not a user on action: ${action.type}`)

      const included = action.payload.included || []

      switch (user.attributes.account_type) {
        case 'parent': {
          const avatars = included.filter(ensureApiType('avatars')).map(compact)
          const o: StoreParentProfile = {
            id: user.id,
            ...user.attributes,
            avatars,
            avatar_url: avatars.at(0)?.image.url ?? null,
            subscription: (() => {
              const s = included.find(ensureApiType('subscriptions'))
              return s ? compact(s) : null
            })(),
          }
          return state?.account_type === 'parent' ? mergeGuarded(state, o, 'subscription', 'phone') : o
        }
        case 'sitter': {
          const avatars = included.filter(ensureApiType('avatars')).map(compact)
          const o: StoreSitterProfile = {
            id: user.id,
            ...user.attributes,
            avatars,
            avatar_url: avatars.at(0)?.image.url ?? null,
            location: (() => {
              const s = included.find(ensureApiType('locations'))
              return s ? compact(s) : null
            })(),
          }
          return state?.account_type === 'sitter' ? mergeGuarded(state, o, 'location', 'phone') : o
        }
        case 'visitor': {
          const o: StoreVisitorProfile = {
            id: user.id,
            ...user.attributes,
            avatar_url: null,
          }
          return state?.account_type === 'visitor' ? mergeGuarded(state, o) : o
        }
        default: {
          return state
        }
      }
    }
  )

  builder.addCase(postTrainingRequestDescriptor.shapes.fulfilled, (state, _action) => {
    if (state?.account_type === 'sitter') {
      return { ...state, has_training_request: true }
    }
    return state
  })

  builder.addCase(addAvatar.shape, (state, action) => {
    if (state && state.account_type !== 'visitor') {
      const avatar = action.payload.avatar
      const newAvatars = state.avatars.concat([avatar])
      const avatar_url = state.avatar_url ?? newAvatars.at(0)?.image.url ?? null
      return { ...state, avatars: newAvatars, avatar_url }
    }
    return state
  })

  builder.addCase(deleteAvatarDescriptor.shapes.pending, (state, action) => {
    if (state && state.account_type !== 'visitor' && state.avatars && state.avatars.length) {
      const avatars = state.avatars.filter(a => a.id !== action.meta.avatarId)
      const avatar_url = avatars.length ? state.avatar_url : null
      return { ...state, avatars, avatar_url }
    }
    return state
  })
})
