/*
 * This file gets bundled and placed in `/build/assets` along with it's assets
 */

import 'react-dates/initialize'
import './rootStyles'

import queryString from 'query-string'
import { createRoot, hydrateRoot } from 'react-dom/client'
import Cookies from 'universal-cookie'

import config from '@app/config'
import { IMPORT_MAP } from '@app/importMap'
import renderApp from '@app/renderers/main'
import { AnalyticsUser } from '@app/types/analytics'

import { CLIENT_ERROR_CODE, IS_PRODUCTION } from '@app/constants/Misc'

import { AbortControllerService } from '@app/services/AbortControllerService'
import { AmoWidgetService } from '@app/services/AmoWidgetService'
import { Amplitude } from '@app/services/Amplitude'
import { analyticsIdentify, AnalyticsPageviewEvent } from '@app/services/AnalyticsEvent'
import { ApplicationAgent } from '@app/services/ApplicationAgent'
import { ClipboardManager } from '@app/services/ClipboardManager'
import { DataRefreshService } from '@app/services/DataRefreshService'
import { DOMService } from '@app/services/DOMService'
import { ErrorReportController } from '@app/services/ErrorReportController'
import { FacebookLoader } from '@app/services/FacebookLoader'
import { IconsCache } from '@app/services/IconsCache'
import { IntlService } from '@app/services/IntlService'
import { Progress } from '@app/services/Progress/Progress'
import { PromiseManager } from '@app/services/PromiseManager'
import { TitleService } from '@app/services/TitleService'
import type { TwilioManager } from '@app/services/TwilioManager'

import { AmoWidgetApiLoadError } from '@app/utils/AmoWidgetApi'
import { asError } from '@app/utils/asError'
import { Cache } from '@app/utils/cache'
import { Channel } from '@app/utils/channel'
import * as errorReport from '@app/utils/errorReport/errorReport'
import { init as sentryInit } from '@app/utils/errorReport/errorReportInitClient'
import { loadScript } from '@app/utils/loadScript'
import { RouteDataExtractor } from '@app/utils/RouteDataExtractor'
import { performRegionCheck } from '@app/utils/routing/actions'
import { history as browserHistory, RouterFactory } from '@app/utils/routing/BrowserRouter'
import { trimRegionalRoute } from '@app/utils/routing/region'
import { Location, LocationDescriptorObject, MatchedRoute } from '@app/utils/routing/types'

import { getApplicationBridge, getContext, restoreLocale, setApplicationBridge } from '@app/store/actions/initial'
import { routerRaiseError, sendBrowserInit } from '@app/store/actions/misc.descriptors'
import { ApiInterceptor } from '@app/store/apiMiddleware/ApiInterceptor'
import { StoreContext } from '@app/store/reduxMiddleware/types'
import { profileUserSelector, registrationCompleteSelector } from '@app/store/selectors/profile'
import { availableRegionsSlugsSelector } from '@app/store/selectors/regions'
import createStore, { Store, StoreState, waitForValue } from '@app/store/store'

import createRoutes from '@app/routes'
import { reducerWithMutator } from '@app/routes/Polygon/helpers'
import { NavItemsManager } from '@app/routes/Static/staticMenu'

export class ClientRunner {
  router!: ReturnType<typeof RouterFactory>
  store!: Store
  cookies!: Cookies
  rootNode!: HTMLElement
  storeContext!: StoreContext
  private twilioManagerChannel = new Channel<TwilioManager | null>()
  private initial = false
  private loadTimestamp: number = (window as any).loadTimestamp

  async load(initialState: StoreState) {
    await this.configureProxy()
    sentryInit({ requestId: initialState.config.appRequestId })

    this.rootNode = document.getElementById('Kidsout')!

    this.cookies = new Cookies(document.cookie)

    this.storeContext = {
      apiInterceptor: new ApiInterceptor(),
      abort: new AbortControllerService(),
      mount: null,
      router: null,
      cookies: this.cookies,
      promiseManager: new PromiseManager(),
      twilio: this.twilioManagerChannel.wait(),
      progress: Progress.shared,
      iconCache: new IconsCache().restore((window as any).iconsCache),
    }

    this.store = createStore(this.storeContext, initialState, browserHistory, initialState.config.isPolygon ? reducerWithMutator : undefined)
    this.store.dispatch(sendBrowserInit())
    const regions = availableRegionsSlugsSelector(this.store.getState())

    this.router = RouterFactory(regions, browserHistory)

    ClipboardManager.shared.isApp = this.store.getState().config.appProtocol >= 2
    ClipboardManager.shared.store = this.store
    this.parseUtmCookie()
    await this.configureApiInterceptor()
    this.sendOriginalLocationDataLayer()
    this.configureThirdParty()
    await this.configureIntlService()
    await this.configureApplicationBridge()
    this.configureApplicationAgent()
    this.configureTitleService()
    this.configureDOMService()
    this.configureDataRefresh()
    this.configureDebug()
    this.configureAmplitude()
    this.configureTwilio()
    this.configureFirebase()
    this.configureAmoWidget()
  }

  async run(location: Location) {
    if (this.initial) {
      this.initial = false
    } else {
      this.loadTimestamp = new Date().getTime()
    }

    const getRoutes = (location: LocationDescriptorObject) => createRoutes(location, this.store)

    const shouldHydrate =
      config.hydration ||
      (!registrationCompleteSelector(this.store.getState()) &&
        trimRegionalRoute(availableRegionsSlugsSelector(this.store.getState()), location.pathname) === '/')

    try {
      const component = await renderApp({
        router: this.router,
        location,
        getRoutes,
        store: this.store,
        onRouteChange: (location, _action, routes) => this.store.dispatch(performRegionCheck(location, routes)),
        cookies: this.cookies,
        afterRender: this.handleAfterRender,
        handleError: this.handleError,
      })
      if (shouldHydrate) {
        hydrateRoot(this.rootNode, component)
      } else {
        const root = createRoot(this.rootNode)
        root.render(component)
      }
      await new Promise<void>(resolve => {
        ;(window.requestIdleCallback ?? setTimeout)(() => {
          resolve()
        })
      })
    } catch (error) {
      this.handleError(error)
    }
  }

  errorReporter = new ErrorReportController()

  private handleError = (e: unknown) => {
    this.errorReporter.report(e)

    const error = e ? asError(e) : null
    const errorCode = error && 'status' in error && typeof error.status === 'number' ? error.status : CLIENT_ERROR_CODE
    const message = error?.message
    const localizedMessage = error && 'localizedMessage' in error && typeof error.localizedMessage === 'string' ? error.localizedMessage : message
    this.store.dispatch(routerRaiseError({ errorCode, message, localizedMessage }))
  }

  private configureApplicationAgent() {
    ApplicationAgent.init(this.store.getState().config.userAgentData!)
  }

  private async configureApiInterceptor() {
    if (!this.store.getState().config.isPolygon) return
    const { apiInterceptor } = this.store.dispatch(getContext())
    const { ApiInterceptor } = await import('@app/routes/Polygon/ApiInterceptor')
    const polygonInterceptor = new ApiInterceptor(apiInterceptor)
    polygonInterceptor.interceptInitial()
  }

  private configureThirdParty() {
    if (!this.store.getState().config.isApp && !this.store.getState().config.isPolygon) {
      FacebookLoader.shared.load().catch(e => {
        console.error(e.message)
      })
      setTimeout(() => {
        if (!window.VK)
          loadScript('//vk.com/js/api/openapi.js').catch(e => {
            console.error(e.message)
          })
      }, 10000) // delay third-party scripts loading so they don't decrease pagespeed score
    }
  }

  private async configureProxy() {
    const proxyParam = queryString.parse(window.location.search).proxy
    const proxySwitcherKey = 'kidsout__proxy-enabled'

    /**
     * Due to a bug in early ios versions, attempting to extend built-in object resolves in TypeError,
     * so we disabling proxying as it using such technique (see ../utils/proxy module)
     *
     * https://stackoverflow.com/questions/26044056/typeerror-attempted-to-assign-to-readonly-property-in-angularjs-application-on
     * https://github.com/mozilla/pdf.js/issues/5353
     */
    const isWebSocketOverridePossible = /OS (8|9)_.*? like Mac OS X/.test(window.navigator.userAgent) === false

    if (typeof proxyParam === 'string') {
      localStorage?.setItem(proxySwitcherKey, proxyParam === 'on' ? 'true' : 'false')
    }
    const proxyEnabled = isWebSocketOverridePossible && localStorage?.getItem(proxySwitcherKey) === 'true'

    if (proxyEnabled) {
      const proxy = await import('@app/utils/proxy')
      proxy.enable()
    }
  }

  private configureAmoWidget() {
    AmoWidgetService.shared.store = this.store

    setTimeout(() => {
      AmoWidgetService.shared.start().catch(e => {
        if (e instanceof AmoWidgetApiLoadError) return
        throw e
      })
    }, 10000) // delay start for 10 seconds so it won't decrease pagespeed score
  }

  private async configureIntlService() {
    const state = this.store.getState()
    await this.store.dispatch(restoreLocale())
    const translationsCache = new Cache<{ [key: string]: string }>(
      new Map(Object.entries(JSON.parse((window as any as { translations: string }).translations)).map(([key, data]) => [key, { data }]))
    )

    await IntlService.init(null, state.locale, translationsCache)
    NavItemsManager.shared.intl = IntlService.shared.current
  }

  private configureTitleService() {
    const titleService = new TitleService(this.store)
    titleService.start()
  }

  private configureDOMService() {
    const domService = new DOMService(this.store)
    domService.start()
  }

  private configureDataRefresh() {
    const dataRefreshService = new DataRefreshService(this.store)
    dataRefreshService.initBrowser().then(() => {
      if (document.hasFocus()) dataRefreshService.start()

      window.addEventListener('blur', () => {
        dataRefreshService.stop()
      })
      window.addEventListener('focus', () => {
        dataRefreshService.start()
      })
    })
  }

  private async configureApplicationBridge() {
    if (!this.store.getState().config.isApp) return
    const { ApplicationBridge } = await import('@app/utils/ApplicationBridge')
    const applicationBridge = new ApplicationBridge()
    applicationBridge.store = this.store
    applicationBridge.registerIncomingHandler()
    this.store.dispatch(setApplicationBridge(applicationBridge))
  }

  private configureDebug() {
    if (!IS_PRODUCTION || config.isStaging) {
      ;(window as any).ReduxStore = this.store
    }
  }

  private async configureTwilio() {
    const state = this.store.getState()
    const {
      config: { isPolygon, isApp },
      session: { supervise },
    } = state
    if (isApp || supervise || isPolygon) {
      this.twilioManagerChannel.resolve(null)
      return
    }

    await waitForValue(this.store, state => {
      const user = profileUserSelector(state)
      return user && user?.account_type !== 'visitor' ? user : null
    })

    const { TwilioManager } = await IMPORT_MAP.twilioManager()
    TwilioManager.initShared(this.store)
    TwilioManager.shared!.connect()
    this.twilioManagerChannel.resolve(TwilioManager.shared)
  }

  private async configureFirebase() {
    const state = this.store.getState()
    const {
      config: { isPolygon },
    } = state
    if (isPolygon) {
      this.storeContext.firebaseManager = null
    }

    await waitForValue(this.store, state => {
      const user = profileUserSelector(state)
      return user && user?.account_type !== 'visitor' ? user : null
    })

    IMPORT_MAP.firebase.manager().then(async m => {
      const manager = new m.FirebaseManager(this.store)
      manager.addEventListener(async e => {
        if (e.type === 'debt_changed') {
          const { fetchRequestsWithDebt } = await import('@app/store/actions/requests')
          this.store.dispatch(fetchRequestsWithDebt())
        }
      })
      await manager.connect()
      this.storeContext.firebaseManager = manager
    })

    IMPORT_MAP.firebase.messagingManager().then(async ({ FirebaseMessagingManager }) => {
      new FirebaseMessagingManager().connect()
    })
  }

  private parseUtmCookie() {
    const params_array = window.location.search.substring(1).split('&')
    const params_result = {}
    for (let i = 0; i < params_array.length; i++) {
      const params_current = params_array[i].split('=')
      params_result[params_current[0]] = typeof params_current[1] === 'undefined' ? '' : params_current[1]
    }
    if (params_result['utm_source']) {
      const date = new Date()
      const postClick = 30
      date.setDate(date.getDate() + postClick)
      document.cookie = 'utm_source=' + params_result['utm_source'] + ';expires=' + date
      document.cookie = 'utm_medium=' + params_result['utm_medium'] + ';expires=' + date
      document.cookie = 'utm_campaign=' + params_result['utm_campaign'] + ';expires=' + date
      document.cookie = 'utm_content=' + params_result['utm_content'] + ';expires=' + date
      document.cookie = 'utm_term=' + params_result['utm_term'] + ';expires=' + date
    }
  }

  private sendOriginalLocationDataLayer() {
    const params_array = window.location.search.substring(1).split('&')
    const params_result = {}
    for (let i = 0; i < params_array.length; i++) {
      const params_current = params_array[i].split('=')
      params_result[params_current[0]] = typeof params_current[1] === 'undefined' ? '' : params_current[1]
    }
    const utm_source = !params_result['utm_source'] ? '' : 'utm_source=' + params_result['utm_source']
    const utm_medium = !params_result['utm_medium'] ? '' : '&utm_medium=' + params_result['utm_medium']
    const utm_campaign = !params_result['utm_campaign'] ? '' : '&utm_campaign=' + params_result['utm_campaign']
    const utm_term = !params_result['utm_term'] ? '' : '&utm_term=' + params_result['utm_term']
    const utm_content = !params_result['utm_content'] ? '' : '&utm_content=' + params_result['utm_content']
    const l_search = utm_source + utm_medium + utm_campaign + utm_term + utm_content

    const win = window as any
    win.dataLayer = win.dataLayer || []
    win.dataLayer.push({
      originalLocation: l_search,
    })
  }

  private configureAmplitude() {
    const state = this.store.getState()
    const {
      config: { isPolygon, amplitude: amplitudeKey, appProtocol, testFlights, appRequestId },
    } = state

    if (isPolygon) return

    Amplitude.shared.addInterceptor(event => !event.startsWith('debug.'))

    Amplitude.shared.init(amplitudeKey, appRequestId)

    if (appProtocol > 1) {
      Amplitude.shared.addInterceptor((event, event_properties) => {
        this.store.dispatch(getApplicationBridge())?.sendMessage({ type: 'amplitude_event', event, event_properties })
        return false
      })
      return
    }

    const user = profileUserSelector(this.store.getState())

    const identify = (user: AnalyticsUser | null) => {
      analyticsIdentify(user)
      errorReport.identify(user)
    }

    const analyticsUser: AnalyticsUser | null = user ? { ...user, test_flights: testFlights } : null
    identify(analyticsUser)

    let metricsUser = user

    this.store.subscribe(() => {
      const prevUser = metricsUser
      metricsUser = profileUserSelector(this.store.getState())

      if (prevUser === metricsUser) return

      if (metricsUser && metricsUser.id && prevUser?.id !== metricsUser.id) {
        const analyticsUser: AnalyticsUser | null = metricsUser ? { ...metricsUser, test_flights: testFlights } : null
        identify(analyticsUser)
      }
    })
  }

  private handleAfterRender = async (
    location: LocationDescriptorObject,
    matchedRoutes: MatchedRoute[],
    /**
     * hard means that location changed drastically,
     * false means location mostly same (only get query changed)
     * and full blown route change routine can be omitted
     */
    _hardNavigation: boolean
  ) => {
    console.info('[RENDERED]', `${new Date().getTime() - this.loadTimestamp}ms`)

    const state = this.store.getState()
    const {
      meta: { title },
    } = state

    const url = location.pathname! + location.search! + location.hash

    const match = matchedRoutes.at(-1)

    const extractor = new RouteDataExtractor(location, this.store.dispatch)
    const data = match ? await extractor.extract(match.route, match.match) : null
    if (data?.event_id) {
      new AnalyticsPageviewEvent(data.event_id, url, match?.route.path ?? '<unknown>', { ...data.event_params, ...data.event_data }, title)
        .sendDataLayer()
        .sendYandex()
        .sendGoogle()
        .sendAmplitude()
        .sendBreadcrumb()
    }
  }
}
