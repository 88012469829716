import { PathMatch } from 'react-router'

import { StoreDispatch } from '@app/store/dispatch'

import { ensureType } from './ensureType'
import { LocationDescriptorObject, Route } from './routing/types'

export class RouteDataExtractor {
  dataExtractionLocation: LocationDescriptorObject | null = null
  dispatch: StoreDispatch

  constructor(dataExtractionLocation: LocationDescriptorObject | null, dispatch: StoreDispatch) {
    this.dataExtractionLocation = dataExtractionLocation ?? null
    this.dispatch = dispatch
  }

  async extract(route: Route, match: PathMatch) {
    return {
      event_id: route.event_id,
      ...(await this.extractEventData(route, match)),
    }
  }

  private async extractEventData(
    route: Route,
    match: PathMatch
  ): Promise<{
    event_params?: Record<string, any>
    event_data?: Record<string, any>
  }> {
    const data = {
      event_params: ensureType<Record<string, any>>({}),
      event_data: ensureType<Record<string, any>>({}),
    }

    if (route.event_params) {
      for (const key in route.event_params) {
        if (Object.prototype.hasOwnProperty.call(route.event_params, key)) {
          data.event_params[key] = match.params[route.event_params[key]]
        }
      }
    }

    if (route.event_data) {
      if (typeof route.event_data === 'function' && this.dataExtractionLocation) {
        data.event_data = { ...data.event_data, ...(await route.event_data(this.dataExtractionLocation, this.dispatch)) }
      } else {
        Object.entries(route.event_data).forEach(([k, v]) => {
          data.event_data[k] = v
        })
      }
    }

    if (!Object.keys(data.event_params).length) {
      Reflect.deleteProperty(data, 'event_params')
    }
    if (!Object.keys(data.event_data).length) {
      Reflect.deleteProperty(data, 'event_data')
    }

    return data
  }
}
