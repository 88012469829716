import { v4 } from 'uuid'

import { SitterSearchQuery } from '@app/constants/ApiTypes/requests'

import { excludeAbortError } from '@app/errors/AbortError'

import { getSitterSearchDescriptor } from '@app/store/actions/api/search.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  /** updates with every new search that starts with page 1 */
  searchId: string
  total: number
  loading: boolean
  continuation?: { query: SitterSearchQuery } | null
}>({ searchId: v4(), total: 0, loading: false }, builder => {
  builder.addCase(getSitterSearchDescriptor.shapes.pending, (state, _action) => {
    return { ...state, loading: true }
  })

  builder.addCase(getSitterSearchDescriptor.shapes.rejected, (state, action) => {
    return { ...state, loading: false, error: excludeAbortError(action.payload) }
  })

  builder.addCase(getSitterSearchDescriptor.shapes.fulfilled, (state, action) => {
    return {
      ...state,
      searchId: action.meta.query.page === 1 ? v4() : state.searchId,
      loading: false,
      page: action.payload.meta.current_page,
      total: action.payload.meta.total,
      continuation:
        action.payload.meta.current_page < action.payload.meta.total_pages
          ? { query: { ...action.meta.query, page: action.payload.meta.current_page + 1 } }
          : null,
    }
  })
})
