/** ISO8601 formatted string */
export type ISO8601 = string

export const IS_PRODUCTION = process.env.NODE_ENV === 'production'

export const IPRegex = /^(\d{1,3}\.){3}\d{1,3}$/

/** Defines datetime of script start */
export const NOW = new Date()

export const WORK_TODAY_ENABLED = true

export const DEFAULT_SCHOOL_PHONE = '8 800 707-80-97'

export const SUPPORT_OFFICE_HOURS = {
  start: '9:00',
  end: '22:00',
}

export const BONUS_POINTS_MAX_AMOUNT = 50_000

export const EXTERNAL_LINKS_REL = 'noopener noreferrer nofollow'
export const EXTERNAL_LINKS_REL_FOLLOW = 'noopener noreferrer'

export const WHATSAPP_SUPPORT = 'https://wa.me/14159677934'

export const VK_LOGIN_ENABLED = false
export const FB_LOGIN_ENABLED = false

export const APP_INFO = {
  parent: {
    ios: {
      title: 'Kidsout — свобода на час',
      id: '1273152870',
      url: 'https://apps.apple.com/app/apple-store/id1273152870?pt=118002178&ct=app-promotion&mt=8',
    },
    android: {
      title: 'Kidsout — свобода на час',
      id: 'ru.kidsout.parent',
      url: 'https://play.google.com/store/apps/details?id=ru.kidsout.parent&utm_source=kidsout&utm_medium=landing&utm_campaign=app-promotion',
      image: require('@app/assets/img/app_icons/parent.png?force_file'),
      developer: 'Detki v Poryadke',
    },
    galaxy: {
      title: 'Kidsout — свобода на час',
      id: 'ru.kidsout.parent',
      url: 'https://galaxystore.samsung.com/detail/ru.kidsout.parent',
      image: require('@app/assets/img/app_icons/parent.png?force_file'),
      developer: 'Detki v Poryadke',
    },
    apk: {
      url: '/apps/android/parent/latest.apk',
    },
  },
  sitter: {
    ios: {
      title: 'Kidsout для ситтеров',
      id: '1375305688',
      url: 'https://apps.apple.com/app/apple-store/id1375305688?pt=118002178&ct=app-promotion&mt=8',
    },
    android: {
      title: 'Kidsout для ситтеров',
      id: 'ru.kidsout.sitter',
      url: 'https://play.google.com/store/apps/details?id=ru.kidsout.sitter&utm_source=kidsout&utm_medium=landing&utm_campaign=app-promotion',
      image: require('@app/assets/img/app_icons/sitter.png?force_file'),
      developer: 'Detki v Poryadke',
    },
    galaxy: {
      title: 'Kidsout для ситтеров',
      id: 'ru.kidsout.sitter',
      url: 'https://galaxystore.samsung.com/detail/ru.kidsout.sitter',
      image: require('@app/assets/img/app_icons/sitter.png?force_file'),
      developer: 'Detki v Poryadke',
    },
    apk: {
      url: '/apps/android/sitter/latest.apk',
    },
  },
}

/** Codes that are part of ApiError.response.errors[number].code */
export const ERROR_CODES = {
  INVALID_PARAMS: 'INVALID_PARAMS',
  VALIDATION_ERROR: 'VALIDATION_ERROR',
} as const

export const MEGAFON_PROMOCODE = 'mf'

export const AUDIT_PROVIDER = {
  name: 'SpectrumData',
  url: 'https://spectrumdata.ru',
  image: require('@app/assets/spectrum_data.svg'),
  width: 176,
  height: 55,
}

export const SOCIAL_CONTACTS = {
  youtube: 'https://www.youtube.com/channel/UC9vgKuVMZKxr0cnvb-z3Eeg',
  vk: 'https://vk.com/kidsout_ru',
  instagram_sitter: 'https://www.instagram.com/babysitter.kidsout',
  instagram_parent: 'https://www.instagram.com/kidsout.app',
  facebook: 'https://www.facebook.com/kidsout.ru',
  telegram: 'https://t.me/kidsout_app',
}

export const CHAT_MODE_FAVORITES = 'CHAT_MODE_FAVORITES'

export const CLIENT_ERROR_CODE = 600

// negative values are default yandex map margins which should be taken into account
export const MOBILE_MAP_ZOOM_MARGIN: [number, number, number, number] = [-33 + 70, -33 + 18, -33 + 82, -33 + 18]
export const MOBILE_MAP_CLUSTERER_ZOOM_MARGIN: [number, number, number, number] = [70, 50, 70, 50]

export const DEFAULT_BOUNDS: ymaps.Bounds = [
  [55.7522 - 0.1, 37.6156 - 0.2],
  [55.7522 + 0.1, 37.6156 + 0.2],
]

export const DEFAULT_SITTER_SEARCH_SORT = '-kidsout_score'

export const FOUNDING_YEAR = 2014

export const SITTER_SCREENING_ENABLED = process.env.MODE !== 'production'
