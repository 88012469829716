import { Middleware } from 'redux'

import { isAbortError } from '@app/errors/AbortError'

import { AnalyticsEvent } from '@app/services/AnalyticsEvent'

import {
  cancelAnnouncementResponseDescriptor,
  createAnnouncementResponseDescriptor,
  declineAnnouncementResponseDescriptor,
  updateAnnouncementResponseDescriptor,
} from '@app/store/actions/announcementResponse.descriptors'
import { postRequestsDescriptor } from '@app/store/actions/api/requests.descriptors'
import {
  getDepersonalizedAnnouncementsSearchDescriptor,
  getDepersonalizedAnnouncementsSearchMapDescriptor,
} from '@app/store/actions/depersonalized_announcements.descriptors'
import { postAuthPhoneRequestCodeDescriptor, postAuthPhoneVerifyDescriptor, postEmailCodeVerificationDescriptor } from '@app/store/actions/profile.descriptors'
import { socialLoginActionDescriptor } from '@app/store/actions/session.descriptors'
import { asNetworkError } from '@app/store/apiMiddleware/errors'

const middleware: Middleware = _store => next => action => {
  // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
  switch (true) {
    case matchAction(action, createAnnouncementResponseDescriptor.shapes.fulfilled): {
      AnalyticsEvent.create({ id: 'create_announcement_response', announcement_id: action.meta.request.announcement_id }).sendGAYS()
      break
    }
    case matchAction(action, createAnnouncementResponseDescriptor.shapes.rejected): {
      new AnalyticsEvent('create_announcement_response_fail', { announcement_id: action.meta.request.announcement_id }).sendAmplitude()
      break
    }
    case matchAction(action, cancelAnnouncementResponseDescriptor.shapes.fulfilled): {
      AnalyticsEvent.create({
        id: 'cancel_announcement_response',
        announcement_id: action.meta.announcement_id,
        announcement_response_id: action.meta.response_id,
      }).sendGAYS()
      break
    }
    case matchAction(action, cancelAnnouncementResponseDescriptor.shapes.rejected): {
      new AnalyticsEvent('cancel_announcement_response_fail', {
        announcement_id: action.meta.announcement_id,
        announcement_response_id: action.meta.response_id,
      }).sendAmplitude()
      break
    }
    case matchAction(action, updateAnnouncementResponseDescriptor.shapes.fulfilled): {
      AnalyticsEvent.create({
        id: 'update_announcement_response',
        announcement_response_id: action.meta.response_id,
        announcement_id: action.meta.announcement_id,
      }).sendGAYS()
      break
    }
    case matchAction(action, updateAnnouncementResponseDescriptor.shapes.rejected): {
      new AnalyticsEvent('create_announcement_response_fail', {
        response_id: action.meta.response_id,
        announcement_id: action.meta.announcement_id,
      }).sendAmplitude()
      break
    }

    case matchAction(action, declineAnnouncementResponseDescriptor.shapes.fulfilled): {
      AnalyticsEvent.create({
        id: 'decline_announcement_response',
        announcement_id: action.meta.announcement_id,
        announcement_response_id: action.meta.response_id,
      }).sendGAYS()
      break
    }
    case matchAction(action, declineAnnouncementResponseDescriptor.shapes.rejected): {
      new AnalyticsEvent('decline_announcement_response_fail', {
        announcement_id: action.meta.announcement_id,
        announcement_response_id: action.meta.response_id,
      }).sendAmplitude()
      break
    }

    case matchAction(action, postRequestsDescriptor.shapes.fulfilled): {
      AnalyticsEvent.create({ id: 'create_request_from_announcement', announcement_response_id: action.meta.announcement_response_id }).sendGAYS()
      break
    }
    case matchAction(action, postRequestsDescriptor.shapes.rejected): {
      new AnalyticsEvent('create_request_from_announcement_fail', { announcement_response_id: action.meta.announcement_response_id }).sendAmplitude()
      break
    }

    case matchAction(action, getDepersonalizedAnnouncementsSearchDescriptor.shapes.pending): {
      new AnalyticsEvent('fetch_depersonalized_announcements.start', {}).sendAmplitude()
      break
    }
    case matchAction(action, getDepersonalizedAnnouncementsSearchDescriptor.shapes.fulfilled): {
      new AnalyticsEvent('fetch_depersonalized_announcements.fetch', {
        request: action.meta.request,
      }).sendAmplitude()
      break
    }
    case matchAction(action, getDepersonalizedAnnouncementsSearchDescriptor.shapes.rejected): {
      new AnalyticsEvent('fetch_depersonalized_announcements.error', {
        error: action.payload.message,
        status: asNetworkError(action.payload)?.status || 'unknown',
      }).sendAmplitude()
      break
    }

    case matchAction(action, getDepersonalizedAnnouncementsSearchMapDescriptor.shapes.pending): {
      new AnalyticsEvent('fetch_depersonalized_announcements_map.start', {}).sendAmplitude()
      break
    }
    case matchAction(action, getDepersonalizedAnnouncementsSearchMapDescriptor.shapes.fulfilled): {
      new AnalyticsEvent('fetch_depersonalized_announcements_map.fetch', {
        request: action.meta.request,
      }).sendAmplitude()
      break
    }
    case matchAction(action, getDepersonalizedAnnouncementsSearchMapDescriptor.shapes.rejected): {
      new AnalyticsEvent('fetch_depersonalized_announcements_map.error', {
        error: action.payload.message,
        status: asNetworkError(action.payload)?.status || 'unknown',
      }).sendAmplitude()
      break
    }

    case matchAction(action, socialLoginActionDescriptor.shapes.pending): {
      new AnalyticsEvent('social_login', {}).sendAmplitude()
      break
    }
    case matchAction(action, socialLoginActionDescriptor.shapes.fulfilled): {
      new AnalyticsEvent('social_login_success', {}).sendAmplitude()
      break
    }
    case matchAction(action, socialLoginActionDescriptor.shapes.rejected): {
      new AnalyticsEvent('social_login_fail', {}).sendAmplitude()
      break
    }

    case matchAction(action, postAuthPhoneRequestCodeDescriptor.shapes.fulfilled): {
      new AnalyticsEvent('phone_verification', { purpose: action.meta.purpose, delivery: action.meta.delivery }).sendAmplitude()
      break
    }
    case matchAction(action, postAuthPhoneRequestCodeDescriptor.shapes.rejected): {
      new AnalyticsEvent('phone_verification_fail', { purpose: action.meta.purpose, delivery: action.meta.delivery }).sendAmplitude()
      break
    }

    case matchAction(action, postAuthPhoneVerifyDescriptor.shapes.pending): {
      new AnalyticsEvent('phone_confirmation', {}).sendAmplitude()
      break
    }
    case matchAction(action, postAuthPhoneVerifyDescriptor.shapes.fulfilled): {
      new AnalyticsEvent('phone_confirmation_success', {}).sendAmplitude()
      break
    }
    case matchAction(action, postAuthPhoneVerifyDescriptor.shapes.rejected): {
      new AnalyticsEvent('phone_confirmation_fail', {}).sendAmplitude()
      break
    }

    case matchAction(action, postEmailCodeVerificationDescriptor.shapes.pending): {
      new AnalyticsEvent('email_code_verification', {}).sendAmplitude()
      break
    }
    case matchAction(action, postEmailCodeVerificationDescriptor.shapes.fulfilled): {
      new AnalyticsEvent('email_code_verification_success', {}).sendAmplitude()
      break
    }
    case matchAction(action, postEmailCodeVerificationDescriptor.shapes.rejected): {
      new AnalyticsEvent('email_code_verification_fail', {}).sendAmplitude()
      break
    }
  }

  return next(action)
}

export default middleware

const matchAction = <S extends { type: string }>(action: unknown, shape: S): action is S => {
  return !!(action && typeof action === 'object' && 'type' in action && typeof action.type === 'string' && action.type === shape.type) && !isAbort(action)
}

const isAbort = (action: unknown) => {
  return !!(action && typeof action === 'object' && 'payload' in action && isAbortError(action.payload))
}
