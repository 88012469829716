import { GetRequestsQuery, RequestCancelRequest, RequestRejectRequest, TrustedOrderRequest } from '@app/constants/ApiTypes/requests'

import * as api from '@app/utils/api'
import { urlEscaped } from '@app/utils/urlEscaped'

import { ApiActionBuilder } from '@app/store/apiMiddleware/builder'

import {
  getRequestsByIdDescriptor,
  getRequestsDescriptor,
  getRequestsWithDebtDescriptor,
  postRequestsByParentIdDescriptor,
  postRequestsDescriptor,
  postRequestsWithDebtRepayDescriptor,
  putRequestsAcceptDescriptor,
  putRequestsCancelDescriptor,
  putRequestsCompleteDescriptor,
  putRequestsDeclineDescriptor,
  putRequestsRejectDescriptor,
  putRequestsStartDescriptor,
} from './requests.descriptors'

export const getRequests = new ApiActionBuilder(getRequestsDescriptor)
  .setInit((query: GetRequestsQuery) => {
    return {
      method: 'GET',
      endpoint: api.path('/api/v2/requests', query),
      headers: api.headers(),
      meta: { query },
    }
  })
  .build()

export const getRequestsById = new ApiActionBuilder(getRequestsByIdDescriptor)
  .setInit((id: string) => ({
    method: 'GET',
    endpoint: api.path(urlEscaped`/api/v2/requests/${id}`),
    headers: api.headers(),
  }))
  .build()

export const postRequests = new ApiActionBuilder(postRequestsDescriptor)
  .setInit((announcement_response_id: string) => ({
    method: 'POST',
    endpoint: api.path('/api/v2/requests/'),
    headers: api.headers(),
    body: JSON.stringify({ announcement_response_id }),
    meta: { announcement_response_id },
  }))
  .build()

export const putRequestsAccept = new ApiActionBuilder(putRequestsAcceptDescriptor)
  .setInit((request_id: string) => ({
    method: 'PUT',
    endpoint: api.path(urlEscaped`/api/v2/requests/${request_id}/accept`),
    headers: api.headers(),
    meta: { request_id },
  }))
  .build()

export const putRequestsDecline = new ApiActionBuilder(putRequestsDeclineDescriptor)
  .setInit((request_id: string) => ({
    method: 'PUT',
    endpoint: api.path(urlEscaped`/api/v2/requests/${request_id}/decline`),
    headers: api.headers(),
    meta: { request_id },
  }))
  .build()

export const putRequestsCancel = new ApiActionBuilder(putRequestsCancelDescriptor)
  .setInit((request_id: string, request: RequestCancelRequest) => ({
    method: 'PUT',
    endpoint: api.path(urlEscaped`/api/v2/requests/${request_id}/cancel`),
    headers: api.headers(),
    body: JSON.stringify(request),
    meta: { request_id, request },
  }))
  .build()

export const putRequestsReject = new ApiActionBuilder(putRequestsRejectDescriptor)
  .setInit((request_id: string, request: RequestRejectRequest) => ({
    method: 'PUT',
    endpoint: api.path(urlEscaped`/api/v2/requests/${request_id}/reject`),
    headers: api.headers(),
    body: JSON.stringify(request),
    meta: { request_id, request },
  }))
  .build()

export const getRequestsWithDebt = new ApiActionBuilder(getRequestsWithDebtDescriptor)
  .setInit(() => ({
    method: 'GET',
    endpoint: api.path('/api/v2/requests/with_debt'),
    headers: api.headers(),
  }))
  .build()

export const postRequestsWithDebtRepay = new ApiActionBuilder(postRequestsWithDebtRepayDescriptor)
  .setInit(() => ({
    method: 'POST',
    endpoint: api.path('/api/v2/requests/with_debt/repay'),
    headers: api.headers(),
  }))
  .build()

/** Creates trusted request */
export const postRequestsByParentId = new ApiActionBuilder(postRequestsByParentIdDescriptor)
  .setInit((parent_id: string, request: TrustedOrderRequest) => ({
    method: 'POST',
    endpoint: api.path(urlEscaped`/api/v2/requests/${parent_id}`),
    headers: api.headers(),
    body: JSON.stringify(request),
    meta: { parent_id, request },
  }))
  .build()

export const putRequestsStart = new ApiActionBuilder(putRequestsStartDescriptor)
  .setInit((id: string) => ({
    method: 'PUT',
    endpoint: api.path(`/api/v2/requests/${encodeURIComponent(id)}/start`),
    headers: api.headers(),
  }))
  .build()

export const putRequestsComplete = new ApiActionBuilder(putRequestsCompleteDescriptor)
  .setInit((id: string) => ({
    method: 'PUT',
    endpoint: api.path(`/api/v2/requests/${encodeURIComponent(id)}/complete`),
    headers: api.headers(),
  }))
  .build()
