import uniq from 'lodash/uniq'

import { getSitterSearchDescriptor } from '@app/store/actions/api/search.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<string[]>([], builder => {
  builder.addCase(getSitterSearchDescriptor.shapes.fulfilled, (state, action) => {
    const ids = action.payload.data.map(u => u.id)
    const { page } = action.meta.query
    if (page === 1) return ids
    return uniq([...state, ...ids])
  })
})
