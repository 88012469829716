import thunkMiddleware from 'redux-thunk'

import { History } from '@app/utils/routing/types'

import { mountMiddleware } from '@app/components/Mount/Mount'

import analyticsMiddleware from './analytics'
import historyMiddleware from './history'
import { ReduxMiddleware, StoreContext } from './types'

export const StoreMiddlewares = (context: StoreContext, history?: History): ReduxMiddleware<any>[] => [
  thunkMiddleware.withExtraArgument(context),
  historyMiddleware(history),
  analyticsMiddleware,
  mountMiddleware,
]
