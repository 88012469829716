import { IMPORT_MAP } from '@app/importMap'

import { SearchParams } from '@app/constants/StoreTypes'

import moment from '@app/utils/moment'
import { AsyncRoute } from '@app/utils/routing/types'

// @routes declaration
export const routes: AsyncRoute[] = [
  {
    name: 'search',
    path: '/search',
    event_id: 'search',
    event_data: async (location, dispatch) => {
      const ignoredKeys: (keyof SearchParams)[] = ['address']
      const { SearchParamsManager } = await import('./utils/SearchParamsManager')
      const manager = SearchParamsManager.fromSearchString(location.search, dispatch, moment())
      const params = manager.effectiveParams
      return Object.keys(params).reduce<Record<string, any>>((c, key) => {
        if (!ignoredKeys.includes(key as any)) c[key] = params[key]
        return c
      }, {})
    },
    component: () => IMPORT_MAP.routes.Search.Search().then(m => m.Search),
  },
]
