import { excludeAbortError } from '@app/errors/AbortError'

import { getSitterSearchDescriptor } from '@app/store/actions/api/search.descriptors'
import { getUsersByIdDescriptor, getUsersByTokenDescriptor } from '@app/store/actions/api/users.descriptors'
import { createReducer } from '@app/store/toolkit'

export interface UserMeta {
  loaded: boolean
  loading: boolean
  error: Error | null
}

const defaultMeta: UserMeta = {
  loaded: false,
  loading: false,
  error: null,
}

export default createReducer<{
  __default: UserMeta
  [key: string]: UserMeta
}>({ __default: defaultMeta }, builder => {
  builder.addCases([getUsersByIdDescriptor.shapes.fulfilled], (state, action) => {
    const error: Error | null = null
    const meta: UserMeta = { ...(state[action.meta.id] || defaultMeta), loading: false, error }
    return { ...state, [action.meta.id]: meta }
  })

  builder.addCases([getUsersByTokenDescriptor.shapes.fulfilled], (state, action) => {
    const error: Error | null = null
    const meta: UserMeta = { ...(state[action.meta.token] || defaultMeta), loading: false, error }
    return { ...state, [action.meta.token]: meta }
  })

  builder.addCase(getUsersByIdDescriptor.shapes.pending, (state, action) => {
    const meta: UserMeta = { loaded: true, loading: true, error: null }
    return { ...state, [action.meta.id]: meta }
  })
  builder.addCase(getUsersByTokenDescriptor.shapes.pending, (state, action) => {
    const meta: UserMeta = { loaded: true, loading: true, error: null }
    return { ...state, [action.meta.token]: meta }
  })

  builder.addCase(getUsersByIdDescriptor.shapes.rejected, (state, action) => {
    const meta: UserMeta = { ...(state[action.meta.id] || defaultMeta), loading: false, error: excludeAbortError(action.payload) }
    return { ...state, [action.meta.id]: meta }
  })

  builder.addCase(getUsersByTokenDescriptor.shapes.rejected, (state, action) => {
    const meta: UserMeta = { ...(state[action.meta.token] || defaultMeta), loading: false, error: excludeAbortError(action.payload) }
    return { ...state, [action.meta.token]: meta }
  })

  builder.addCase(getSitterSearchDescriptor.shapes.fulfilled, (state, action) => {
    const users = action.payload.data
    const patch = users.reduce<{ [key: string]: UserMeta }>((acc, u) => {
      acc[u.attributes.token] = { loaded: true, loading: false, error: null }
      return acc
    }, {})
    return { ...state, ...patch }
  })
})
