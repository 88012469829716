import moment from '@app/utils/moment'

import { ApiActionPromise } from '@app/store/apiMiddleware/types'

import { MetaManager } from './MetaManager'

export const wrapApiPromise = async <P extends ApiActionPromise<any>>(
  promise: P,
  abort: () => void,
  manager: MetaManager<{ loading?: boolean; loadedAt?: string; abort?: () => void; getPromise?: () => P }>
): Promise<Awaited<P>> => {
  manager.setMeta({ ...manager.getMeta(), loading: true, abort: () => abort(), getPromise: () => promise })
  try {
    const resp = await promise
    if (resp) {
      if (resp.error) throw resp.payload
      manager.setMeta({ ...manager.getMeta(), loading: false, loadedAt: moment().format(), getPromise: undefined, abort: undefined })
    }
    return resp
  } catch (e) {
    manager.setMeta({ ...manager.getMeta(), loading: false, getPromise: undefined, abort: undefined })
    throw e
  }
}
