import type { ApiRequest } from '@app/constants/ApiTypes/entities'
import { ApiDataResponse, type ApiRequestMetaData } from '@app/constants/ApiTypes/misc'
import { GetRequestsQuery, RequestCancelRequest, RequestRejectRequest, TrustedOrderRequest } from '@app/constants/ApiTypes/requests'

import { ApiActionBuilderDescriptor } from '@app/store/apiMiddleware/builderDescriptor'

export const getRequestsDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('getRequests')
  .setShape<ApiDataResponse<ApiRequest[]> & { meta: ApiRequestMetaData }, { query: GetRequestsQuery }>()

export const getRequestsByIdDescriptor = new ApiActionBuilderDescriptor().setLabel('getRequestsById').setShape<ApiDataResponse<ApiRequest>>()

export const postRequestsDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('postRequests')
  .setShape<ApiDataResponse<ApiRequest>, { announcement_response_id: string }>()

export const putRequestsAcceptDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('putRequestsAccept')
  .setShape<ApiDataResponse<ApiRequest>, { request_id: string }>()

export const putRequestsDeclineDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('putRequestsDecline')
  .setShape<ApiDataResponse<ApiRequest>, { request_id: string }>()

export const putRequestsCancelDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('putRequestsCancel')
  .setShape<ApiDataResponse<ApiRequest>, { request_id: string; request: RequestCancelRequest }>()

export const putRequestsRejectDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('putRequestsReject')
  .setShape<ApiDataResponse<ApiRequest>, { request_id: string; request: RequestRejectRequest }>()

export const getRequestsWithDebtDescriptor = new ApiActionBuilderDescriptor().setLabel('getRequestsWithDebt').setShape<ApiDataResponse<ApiRequest[]>>()

export const postRequestsWithDebtRepayDescriptor = new ApiActionBuilderDescriptor().setLabel('postRequestsWithDebtRepay').setShape<unknown>()

export const postRequestsByParentIdDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('postRequestsByParentId')
  .setShape<undefined, { parent_id: string; request: TrustedOrderRequest }>()

export const putRequestsStartDescriptor = new ApiActionBuilderDescriptor().setLabel('putRequestsStart').setShape<ApiDataResponse<ApiRequest>>()

export const putRequestsCompleteDescriptor = new ApiActionBuilderDescriptor().setLabel('putRequestsComplete').setShape<ApiDataResponse<ApiRequest>>()
