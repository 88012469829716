import { ApiDirectAnnouncement } from '@app/constants/ApiTypes/entities'

import { mergeV2Entity } from '@app/utils/mergeV2Entity'

import { getDirectAnnouncementsByIdDescriptor, putDirectAnnouncementsCancelDescriptor } from '@app/store/actions/api/direct_announcements.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  [key: string]: ApiDirectAnnouncement
}>({}, builder => {
  builder.addCases([getDirectAnnouncementsByIdDescriptor.shapes.fulfilled, putDirectAnnouncementsCancelDescriptor.shapes.fulfilled], (state, action) => {
    const id = action.payload.data.id
    return {
      ...state,
      [id]: mergeV2Entity(state[id], action.payload.data),
    }
  })
})
