import { ApiMetaTags } from '@app/constants/ApiTypes/entities'

import { apiNormalize } from '@app/utils/apiNormalizer'
import { mergeMaps } from '@app/utils/mergeMaps'

import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  [key: string]: ApiMetaTags
}>({}, builder => {
  builder.setDefaultHandler((state, action) => {
    const { meta_tags, users = {} } = apiNormalize(action)
    if (!meta_tags) return state

    const metaTagsByUserId = Object.fromEntries(
      Object.values(users).flatMap(user => {
        if (user.attributes.account_type !== 'sitter') return []
        const meta_id = 'meta_tags' in user.relationships ? user.relationships.meta_tags.data?.id : null
        if (!meta_id) return []
        const meta = meta_tags[meta_id]
        if (!meta) throw new Error(`Meta for user ${user.id} not found`)
        return [
          [user.id, meta],
          [user.attributes.token, meta],
        ]
      })
    )

    return mergeMaps(state, metaTagsByUserId)
  })
})
