import mapKeys from 'lodash/mapKeys'
import { defineMessages } from 'react-intl'

import config from '@app/config'

import type { TrackingEvent } from '@app/constants/ApiTypes/misc'
import type { PlaygroundCreationRequest, SchoolSignUpRequest } from '@app/constants/ApiTypes/requests'
import { MISC_MESSAGES } from '@app/constants/Messages/misc'

import { AbortError } from '@app/errors/AbortError'

import * as api from '@app/utils/api'
import { read as readSource } from '@app/utils/sourceRecorder/read'

import { createAddress } from '@app/packages/geo/Address'
import { MAP_AREA_KIND } from '@app/packages/geo/constants'

import { CloudPaymentsRequest, loadCloudPayments } from '@app/hooks/useCloudPayments'

import { ApiActionBuilder } from '@app/store/apiMiddleware/builder'
import { intlSelector } from '@app/store/selectors/misc'
import { createThunk } from '@app/store/thunk'
import { ConfigState } from '@app/store/types/config'

import { pastSchoolRequestsDescriptor, postPlaygroundRequestsDescriptor, postTrackingsDescriptor, setConfigAction } from './misc.descriptors'

export const setConfig = (partialConfig: Partial<ConfigState>) => {
  return createThunk(dispatch => {
    dispatch(setConfigAction(partialConfig))
    Object.assign(config, partialConfig)
  })
}

export const postSchoolRequests = new ApiActionBuilder(pastSchoolRequestsDescriptor)
  .setInit((form: SchoolSignUpRequest) => ({
    method: 'POST',
    endpoint: api.path('/api/v2/school_requests'),
    headers: api.headers(),
    body: JSON.stringify(form),
  }))
  .build()

export const postPlaygroundRequests = new ApiActionBuilder(postPlaygroundRequestsDescriptor)
  .setInit((form: PlaygroundCreationRequest) => ({
    method: 'POST',
    endpoint: api.path('/api/v2/playground_requests'),
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({ ...form, date: form.date!.format('YYYY-MM-DD') }),
  }))
  .build()

export const postTrackingsAction = new ApiActionBuilder(postTrackingsDescriptor)
  .setInit((source: Record<string, string | object>, event: TrackingEvent) => ({
    method: 'POST',
    endpoint: api.path('/api/v2/trackings'),
    headers: api.headers(),
    body: JSON.stringify({ ...source, event_type: event.type, event_data: event.data }),
  }))
  .build()

export function postTrackings(event: TrackingEvent) {
  return createThunk((dispatch, _getState, { cookies }) => {
    const source = mapKeys(readSource(cookies), (_value, key) => {
      if (key.indexOf('utm_') === 0) return key.replace(/^utm_/, '')
      return key
    })

    return dispatch(postTrackingsAction(source, event))
  })
}

export class CloudPaymentsChargeCancelledError extends AbortError {}

export function cloudPaymentsCharge(request: Omit<CloudPaymentsRequest, 'publicId' | 'skin'>) {
  return createThunk(async (_dispatch, getState) => {
    const cloudPayments = await loadCloudPayments()
    const cloudPaymentsId = getState().config.cloudpayments

    const widget = new cloudPayments.CloudPayments()

    return await new Promise<void>((resolve, reject) => {
      widget.pay(
        'charge',
        {
          ...request,
          publicId: cloudPaymentsId,
          skin: 'modern',
        },
        {
          onSuccess: () => {
            resolve()
          },
          onFail: c => {
            if (c === 'User has cancelled') {
              reject(new CloudPaymentsChargeCancelledError(c))
            } else {
              reject(new Error(c))
            }
          },
        }
      )
    })
  })
}

export function getAddressFromBounds(bounds: ymaps.Bounds) {
  return createThunk((_dispatch, getState) => {
    const { formatMessage } = intlSelector(getState())

    return createAddress({
      label: process.env.NODE_ENV !== 'production' ? JSON.stringify(bounds) : formatMessage(messages.map_area),
      kind: MAP_AREA_KIND,
      location: {
        lat: (bounds[0][0] + bounds[1][0]) / 2,
        lon: (bounds[0][1] + bounds[1][1]) / 2,
      },
      bounds: {
        bottomLeft: { lat: bounds[0][0], lon: bounds[0][1] },
        topRight: { lat: bounds[1][0], lon: bounds[1][1] },
      },
    })
  })
}

export function formatDistance(
  /** Distance in meters */
  distance: number
) {
  return createThunk((_dispatch, getState) => {
    const { formatMessage } = intlSelector(getState())
    const km = Math.floor(distance / 1000)
    const m = distance % 1000
    return [km ? formatMessage(MISC_MESSAGES.km, { distance: km }) : null, m || !km ? formatMessage(MISC_MESSAGES.m, { distance: m }) : null]
      .filter(Boolean)
      .join(' ')
  })
}

const messages = defineMessages({
  map_area: 'Участок карты',
})
