import { ApiAnnouncementResponse } from '@app/constants/ApiTypes/entities'

import { createMap } from '@app/utils/createMap'
import { isTruthy } from '@app/utils/isTruthy'
import { mapObject } from '@app/utils/mapOpject'
import { mergeV2Entity } from '@app/utils/mergeV2Entity'
import { modifyV2EntityAttributes } from '@app/utils/modifyV2EntityAttributes'
import { normalize } from '@app/utils/normalizer'

import {
  cancelAnnouncementResponseDescriptor,
  createAnnouncementResponseDescriptor,
  declineAnnouncementResponseDescriptor,
  fetchAnnouncementResponseDescriptor,
  updateAnnouncementResponseDescriptor,
} from '@app/store/actions/announcementResponse.descriptors'
import {
  getAnnouncementsByIdDescriptor,
  getAnnouncementsByQueryDescriptor,
  getAnnouncementsSearchDescriptor,
  getAnnouncementsUpcomingDescriptor,
} from '@app/store/actions/api/announcements.descriptors'
import {
  getRequestsByIdDescriptor,
  getRequestsDescriptor,
  getRequestsWithDebtDescriptor,
  postRequestsDescriptor,
  putRequestsCancelDescriptor,
  putRequestsRejectDescriptor,
} from '@app/store/actions/api/requests.descriptors'
import { getSavedAnnouncementSearchesByIdAnnouncementsDescriptor } from '@app/store/actions/api/saved_announcement_searches.descriptors'
import { firebaseBatch } from '@app/store/actions/firebase.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{
  [key: string]: ApiAnnouncementResponse
}>({}, builder => {
  builder.addCases(
    [
      putRequestsCancelDescriptor.shapes.fulfilled,
      createAnnouncementResponseDescriptor.shapes.fulfilled,
      postRequestsDescriptor.shapes.fulfilled,
      fetchAnnouncementResponseDescriptor.shapes.fulfilled,
      getRequestsDescriptor.shapes.fulfilled,
      getRequestsByIdDescriptor.shapes.fulfilled,
      getAnnouncementsByIdDescriptor.shapes.fulfilled,
      getAnnouncementsByQueryDescriptor.shapes.fulfilled,
      getAnnouncementsSearchDescriptor.shapes.fulfilled,
      getAnnouncementsUpcomingDescriptor.shapes.fulfilled,
      getRequestsWithDebtDescriptor.shapes.fulfilled,
      getSavedAnnouncementSearchesByIdAnnouncementsDescriptor.shapes.fulfilled,
      putRequestsRejectDescriptor.shapes.fulfilled,
    ],
    (state, action) => {
      const { announcement_responses } = normalize(action.payload)
      if (!announcement_responses) return state

      return { ...state, ...mapObject(announcement_responses, r => mergeV2Entity(state[r.id], r)) }
    }
  )
  builder.addCases([cancelAnnouncementResponseDescriptor.shapes.fulfilled, declineAnnouncementResponseDescriptor.shapes.fulfilled], (state, action) => {
    const id = action.payload.data.id
    const response = state[id]

    if (!response) return state

    return {
      ...state,
      [id]: modifyV2EntityAttributes(response, action.type === 'CANCEL_ANNOUNCEMENT_RESPONSE_FULFILLED' ? { status: 'canceled' } : { status: 'declined' }),
    }
  })
  builder.addCase(firebaseBatch.shape, (state, action) => {
    const responses = createMap(
      Object.values(action.payload.announcement_responses.models)
        .map(response => {
          const storeResp = state[response.id]
          if (!storeResp) return null
          return { ...storeResp, attributes: { ...storeResp.attributes, status: response.attributes.status } }
        })
        .filter(isTruthy),
      r => r.id
    )

    return { ...state, ...responses }
  })
  builder.addCase(updateAnnouncementResponseDescriptor.shapes.fulfilled, (state, action) => {
    if (!state[action.meta.response_id]) return state
    return { ...state, [action.meta.response_id]: mergeV2Entity(state[action.meta.response_id], action.payload.data) }
  })
})
