import type { ApiGiftCard, ApiGiftCardType } from '@app/constants/ApiTypes/entities'
import { ApiDataResponse } from '@app/constants/ApiTypes/misc'

import { ApiActionBuilderDescriptor } from '@app/store/apiMiddleware/builderDescriptor'
import { createPayloadlessAction } from '@app/store/toolkit'

export const restoreGiftcardOrder = createPayloadlessAction('RESTORE_GIFTCARD_ORDER')

export const getGiftCardsTypesDescriptor = new ApiActionBuilderDescriptor().setLabel('GET_GIFT_CARDS_TYPES').setShape<ApiDataResponse<ApiGiftCardType>>()
export const postGiftCardsDescriptor = new ApiActionBuilderDescriptor().setLabel('POST_GIFT_CARDS').setShape<ApiDataResponse<ApiGiftCard>>()
export const getGiftCardsByIdDescriptor = new ApiActionBuilderDescriptor().setLabel('GET_GIFT_CARDS_BY_ID').setShape<ApiDataResponse<ApiGiftCard>>()
