import { getState } from '@app/store/actions/initial'
import { StoreDispatch } from '@app/store/dispatch'
import { StoreState } from '@app/store/store'
import { createAction } from '@app/store/toolkit'

export class MetaManager<M> {
  readonly label: string
  private defaultMeta: M
  private dispatch: StoreDispatch

  constructor(label: string, defaultMeta: M, dispatch: StoreDispatch) {
    this.label = label
    this.defaultMeta = defaultMeta
    this.dispatch = dispatch
  }

  setMeta(meta: M) {
    this.dispatch(setMeta({ label: this.label, meta }))
  }

  resetMeta() {
    this.dispatch(setMeta({ label: this.label, meta: undefined }))
  }

  getMeta() {
    return this.dispatch(getState(this.selector))
  }

  selector = (state: StoreState): M => {
    return state.reduxmeta[this.label] ?? this.defaultMeta
  }
}

export const setMeta = createAction<'MetaManager/setMeta', { label: string; meta: any }>('MetaManager/setMeta')

export const createManager =
  <M>(label: string, defaultMeta: M) =>
  (dispatch: StoreDispatch) =>
    new MetaManager(label, defaultMeta, dispatch)
