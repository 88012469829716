import { ApiParentProfile, ApiPerk, ApiSitterProfile, ApiUser, ApiUserProfile } from '@app/constants/ApiTypes/entities'
import { ApiDataResponse } from '@app/constants/ApiTypes/misc'

import { ApiActionBuilderDescriptor } from '@app/store/apiMiddleware/builderDescriptor'

export const getUsersByIdDescriptor = new ApiActionBuilderDescriptor().setLabel('getUsersById').setShape<ApiDataResponse<ApiUser>, { id: string }>()

export const getUsersByTokenDescriptor = new ApiActionBuilderDescriptor().setLabel('getUsersByToken').setShape<ApiDataResponse<ApiUser>, { token: string }>()

export const getUsersByFilterDescriptor = new ApiActionBuilderDescriptor().setLabel('getUsersByFilter').setShape<ApiDataResponse<ApiUser[]>>()

export const getUsersMeDescriptor = new ApiActionBuilderDescriptor().setLabel('getUsersMe').setShape<ApiDataResponse<ApiUserProfile>>()

export const putUsersByIdDescriptor = new ApiActionBuilderDescriptor().setLabel('putUsersById').setShape<ApiDataResponse<ApiSitterProfile | ApiParentProfile>>()

export const deleteUsersByIdDescritor = new ApiActionBuilderDescriptor().setLabel('deleteUsersById').setShape<ApiDataResponse<ApiUserProfile>>()

export const getUsersByIdPerksDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('getUsersByIdPerks')
  .setShape<ApiDataResponse<ApiPerk[]>, { user_id: string }>()

export const putUsersByIdPerksDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('putUsersByIdPerks')
  .setShape<ApiDataResponse<ApiPerk[]>, { user_id: string; perk_ids: string[] }>()

export const putUsersByIdPhoneDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('putUsersByIdPhone')
  .setShape<ApiDataResponse<ApiUserProfile>, { user_id: string; request: { phone: string; code: string } }>()
