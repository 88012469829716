import uniq from 'lodash/uniq'

import { getRequestsDescriptor } from '@app/store/actions/api/requests.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<string[]>([], builder => {
  builder.addCase(getRequestsDescriptor.shapes.fulfilled, (state, action) => {
    const keys = action.payload.data.map(model => model.id)
    return action.payload.meta.current_page === 1 ? keys : uniq(state.concat(keys))
  })
})
