import { ApiPerk } from '@app/constants/ApiTypes/entities'

import { apiNormalize } from '@app/utils/apiNormalizer'

import { createReducer } from '@app/store/toolkit'

export default createReducer<{ [key: string]: ApiPerk }>({}, builder => {
  builder.setDefaultHandler((state, action) => {
    const { perks = {} } = apiNormalize(action)
    return { ...state, ...perks }
  })
})
