import { reduceRectangles } from '@app/utils/geometry'

import { createReducer } from '@app/store/toolkit'

import { setMapSearchResult } from '@app/routes/Search/actions.descriptors'

export default createReducer<ymaps.Bounds[]>([], builder => {
  builder.addCase(setMapSearchResult.shape, (state, action) => {
    const bounds = action.payload.bounds
    if (!bounds) return state
    let newState = action.payload.initial ? [] : [...state]
    newState.push(bounds)
    newState = reduceRectangles(newState)
    return newState
  })
})
