import { getUsersByIdPerksDescriptor, putUsersByIdPerksDescriptor } from '@app/store/actions/api/users.descriptors'
import { createReducer } from '@app/store/toolkit'

export default createReducer<{ [key: string]: string[] }>({}, builder => {
  builder.addCase(getUsersByIdPerksDescriptor.shapes.fulfilled, (state, action) => {
    return { ...state, [action.meta.user_id]: action.payload.data.map(p => p.id) }
  })
  builder.addCase(putUsersByIdPerksDescriptor.shapes.fulfilled, (state, action) => {
    return { ...state, [action.meta.user_id]: action.payload.data.map(p => p.id) }
  })
})
