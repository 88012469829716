import keyBy from 'lodash/keyBy'

import { Point } from '@app/packages/geo/Point'

import { createReducer } from '@app/store/toolkit'

import { setMapSearchResult } from '@app/routes/Search/actions.descriptors'

export default createReducer<Record<string, { id: string; token: string; name: string; coords: Point }>>({}, builder => {
  builder.addCase(setMapSearchResult.shape, (state, action) => {
    const usersMap = keyBy(action.payload.list, 'token')
    if (action.payload.initial) return usersMap
    return { ...state, ...usersMap }
  })
})
