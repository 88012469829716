import { Task, TaskWorker } from '@app/packages/task/Task'

import { StoreDispatch } from '@app/store/dispatch'
import { createThunk } from '@app/store/thunk'

export const createTask =
  <S, W extends TaskWorker>(s: symbol, taskCreator: (dispatch: StoreDispatch) => Task<S, W>) =>
  () =>
    createThunk((dispatch, _getState, context) => {
      context[s] = context[s] ?? taskCreator(dispatch)
      return context[s] as Task<S, W>
    })
