import type { ApiAnnouncementResponse } from '@app/constants/ApiTypes/entities'
import { ApiDataResponse } from '@app/constants/ApiTypes/misc'
import type {
  CreateDirectAnnouncementResponseRequest,
  PostAnnouncementResponsesRequest,
  PutAnnouncementResponsesRequest,
} from '@app/constants/ApiTypes/requests'

import { ApiActionBuilderDescriptor } from '@app/store/apiMiddleware/builderDescriptor'

export const createAnnouncementResponseDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('CREATE_ANNOUNCEMENT_RESPONE')
  .setShape<ApiDataResponse<ApiAnnouncementResponse>, { request: PostAnnouncementResponsesRequest }>()

export const createDirectAnnouncementResponseDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('CREATE_DIRECT_ANNOUNCEMENT_RESPONSE')
  .setShape<ApiDataResponse<ApiAnnouncementResponse>, { direct_announcement_id: string; request: CreateDirectAnnouncementResponseRequest }>()

export const cancelAnnouncementResponseDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('CANCEL_ANNOUNCEMENT_RESPONSE')
  .setShape<ApiDataResponse<ApiAnnouncementResponse>, { response_id: string; announcement_id: string }>()

export const updateAnnouncementResponseDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('UPDATE_ANNOUNCEMENT_RESPONSE')
  .setShape<ApiDataResponse<ApiAnnouncementResponse>, { response_id: string; announcement_id: string; request: PutAnnouncementResponsesRequest }>()

export const declineAnnouncementResponseDescriptor = new ApiActionBuilderDescriptor().setLabel('DECLINE_ANNOUNCEMENT_RESPONSE').setShape<
  ApiDataResponse<ApiAnnouncementResponse>,
  {
    announcement_id: string
    response_id: string
  }
>()

export const fetchAnnouncementResponseDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('FETCH_ANNOUNCEMENT_RESPONSE')
  .setShape<ApiDataResponse<ApiAnnouncementResponse>, { response_id: string }>()
