import { ApiAvatar, ApiInaccurateLocation, ApiMetaTags, ApiSitter } from '@app/constants/ApiTypes/entities'
import { ApiRelationship } from '@app/constants/ApiTypes/misc'
import { SitterSearchQuery } from '@app/constants/ApiTypes/requests'

import { ApiActionBuilderDescriptor } from '@app/store/apiMiddleware/builderDescriptor'

export const getSitterSearchDescriptor = new ApiActionBuilderDescriptor().setLabel('getSitterSearch').setShape<
  {
    data: ApiSitter[]
    included?: (ApiInaccurateLocation | ApiAvatar | ApiMetaTags)[]
    meta: { total: number; total_pages: number; current_page: number }
  },
  { query: SitterSearchQuery }
>()

export const getSearchUsersLocationsDescriptor = new ApiActionBuilderDescriptor().setLabel('getSearchUsersLocations').setShape<
  {
    data: {
      id: string
      type: 'users'
      attributes: {
        token: string
        name: string
      }
      relationships: {
        inaccurate_location: ApiRelationship<ApiInaccurateLocation>
      }
    }[]
    included: ApiInaccurateLocation[]
    meta: {
      total: number
      total_pages: number
      current_page: number
    }
  },
  { query: SitterSearchQuery }
>()
