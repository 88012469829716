import React, { memo } from 'react'
import { useSelector } from 'react-redux'

import { EXTERNAL_LINKS_REL, SOCIAL_CONTACTS } from '@app/constants/Misc'

import { useRouter } from '@app/utils/routing/hooks'
import { pathIsSubPathOf } from '@app/utils/url'

import { metaCorpEnabledSelector } from '@app/store/selectors/misc'

import { Icon } from '@app/components/Icon/Icon'

import classes from './SocialButtons.module.scss'

export interface SocialButtonsProps {
  className?: string
  classes?: {
    button?: string
  }
}

export const SocialButtons = memo<SocialButtonsProps>(function SocialButton({ className, classes: theme = {} }) {
  const router = useRouter()
  const version = pathIsSubPathOf('/babysitter', router.location.pathname) ? 'sitter' : 'parent'
  const metaCorpEnabled = useSelector(metaCorpEnabledSelector)

  return (
    <div className={cn(classes.root, className)}>
      <a className={cn(classes.button, theme.button)} href={SOCIAL_CONTACTS.vk} rel={EXTERNAL_LINKS_REL} target="_blank">
        <Icon icon="vk" size="18" />
      </a>
      <a className={cn(classes.button, theme.button)} href={SOCIAL_CONTACTS.youtube} rel={EXTERNAL_LINKS_REL} target="_blank">
        <Icon icon="youtube" size="18" />
      </a>
      {metaCorpEnabled && (
        <a
          className={cn(classes.button, theme.button)}
          href={version === 'sitter' ? SOCIAL_CONTACTS.instagram_sitter : SOCIAL_CONTACTS.instagram_parent}
          rel={EXTERNAL_LINKS_REL}
          target="_blank"
        >
          <Icon icon="instagram" size="18" />
        </a>
      )}
      {metaCorpEnabled && (
        <a className={cn(classes.button, theme.button)} href={SOCIAL_CONTACTS.facebook} rel={EXTERNAL_LINKS_REL} target="_blank">
          <Icon icon="facebook" size="18" />
        </a>
      )}
      <a className={cn(classes.button, theme.button)} href={SOCIAL_CONTACTS.telegram} rel={EXTERNAL_LINKS_REL} target="_blank">
        <Icon icon="telegram" size="18" />
      </a>
    </div>
  )
})
