import type { ApiAcademyCourse, ApiAcademyLesson, ApiAcademyLessonRecord } from '@app/constants/ApiTypes/entities'
import { ApiDataResponse } from '@app/constants/ApiTypes/misc'

import { ApiActionBuilderDescriptor } from '@app/store/apiMiddleware/builderDescriptor'
import type { AcademyState } from '@app/store/reducers/academy'
import { createAction, createPayloadlessAction } from '@app/store/toolkit'

export const setAcademyCartMeta = createAction<'SET_ACADEMY_CART_META', Partial<AcademyState['cartMeta']>>('SET_ACADEMY_CART_META')
export const setAcademyCart = createAction<'SET_ACADEMY_CART', Partial<AcademyState['cart']>>('SET_ACADEMY_CART')
export const setAcademyInvoice = createAction<'SET_ACADEMY_INVOICE', AcademyState['cartInvoice']>('SET_ACADEMY_INVOICE')
export const setAcademyInvoiceMeta = createAction<'SET_ACADEMY_INVOICE_META', Partial<AcademyState['cartInvoiceMeta']>>('SET_ACADEMY_INVOICE_META')
export const setAcademyPromocode = createAction<'SET_ACADEMY_PROMOCODE', AcademyState['promocode']>('SET_ACADEMY_PROMOCODE')
export const setAcademyFilter = createAction<'SET_ACADEMY_FILTER', AcademyState['filter']>('SET_ACADEMY_FILTER')
export const setAcademyProfile = createAction<'SET_ACADEMY_PROFILE', AcademyState['profile']>('SET_ACADEMY_PROFILE')
export const setAcademyLoginFormEmail = createAction<'SET_ACADEMY_LOGIN_FORM_EMAIL', string>('SET_ACADEMY_LOGIN_FORM_EMAIL')

export const getAcademyLessonsDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('GET_ACADEMY_LESSONS')
  .setShape<ApiDataResponse<ApiAcademyLesson[]>, { course_id?: string }>()

export const getAcademyLessonsByIdDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('GET_ACADEMY_LESSONS_BY_ID')
  .setShape<ApiDataResponse<ApiAcademyLesson>>()

export const getAcademyCoursesDescriptor = new ApiActionBuilderDescriptor().setLabel('GET_ACADEMY_COURSES').setShape<{ data: ApiAcademyCourse[] }>()

export const getAcademyCourseByIdDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('GET_ACADEMY_COURSES_BY_ID')
  .setShape<ApiDataResponse<ApiAcademyCourse>, { course_id: string }>()

export const resetAcademyLessonRecords = createPayloadlessAction('RESET_ACADEMY_LESSON_RECORDS')

export const getAcademyProfileLessonRecordsDescriptor = new ApiActionBuilderDescriptor().setLabel('GET_ACADEMY_PROFILE_LESSON_RECORDS').setShape<
  ApiDataResponse<ApiAcademyLessonRecord[]> & { meta: { total: number; total_pages: number; current_page: number } },
  {
    email_token?: string
    page: number
    per_page: number
    order_by: 'start_time'
    order_dir: 'asc' | 'desc'
    upcoming?: boolean
  }
>()

export const postAcademyLoginFormDescriptor = new ApiActionBuilderDescriptor().setLabel('POST_ACADEMY_LOGIN_FORM').setShape<undefined>()
