import React, { FunctionComponent, memo, useCallback } from 'react'

import { IMPORT_MAP } from '@app/importMap'

import { useAppDispatch } from '@app/utils/redux'
import { RouteDataExtractor } from '@app/utils/RouteDataExtractor'
import { useRouter } from '@app/utils/routing/hooks'

import { useEvent } from '@app/hooks/useEvent'

import { getMount } from '@app/store/actions/initial'

import { Button, ButtonProps } from './Button/Button'

export interface Props {
  id: string
  type?: ButtonProps['type']
  className?: string
  children?: React.ReactNode
}

export const LoginButton: FunctionComponent<Props> = memo<Props>(function LoginButton({ id, type, className, children }) {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const handleClick = useCallback(async () => {
    dispatch(getMount())!.push(IMPORT_MAP.modals.LoginModal().then(m => m.LoginModal))
  }, [dispatch])

  const getEventParams = useEvent(async () => {
    if (!router.route) throw new Error('Unexpectedly missing route')
    const extractor = new RouteDataExtractor(null, dispatch)
    const data = await extractor.extract(router.route, router.match)
    return {
      id,
      router_path: router.route?.path,
      ...{ event_id: data.event_id, event_params: data.event_params },
    }
  })

  return (
    <Button className={className} eventName="login_button" eventParams={getEventParams} onClick={handleClick} type={type}>
      {children}
    </Button>
  )
})
